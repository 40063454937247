import { ref, toValue } from 'vue';
import type { Ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import {
  get,
  set,
  useDebounceFn,
  useThrottleFn
} from '@vueuse/core';
import api from '@/api/api';

interface RequiredVerticals {
  [index: string]: number,
}

interface ArticlePoolVertical {
  [index: string]: number[],
}

interface Input {
  count: number,
  verticals: string[],
  requiredVerticals: RequiredVerticals,
  formats: string[],
  tags: string[],
  series: string[],
  featured: boolean,
  highlighted: boolean,
  editorsChoice: boolean,
}

interface Options {
  skipPool?: boolean,
  exclude?: string[],
}

interface QueueItem {
  params: Input,
  resolve: any,
  reject: any,
  fetchCount: number,
}

interface Totals {
  count: number,
  requiredVerticals: RequiredVerticals,
}

/**
 * 1. Add params to the queue, get back a promise
 * 2. Try to populate the queued items from the articlePool and resolve promises where we can
 * 3. Debounce at this point
 * 3. Any remaining means a new fetch
 * 4. Back to #2
 *
 */

// Articles need to be in order from newest to oldest
// Articles need to be filtered

const bufferMultiplier = 3;

let offset = 0;
let isFetching = false;
let isPopulating = false;
const queue: Ref<QueueItem[]> = ref([]);
const articlePool: Ref<any[]> = ref([]);
const articlePoolUsed: Ref<any[]> = ref([]);
let articlePoolVertical: ArticlePoolVertical = {};

// We need to reset everything when we navigate to a new page, throttled to make sure only 1 runs
const throttledRouteChange = useThrottleFn(() => {
  articlePool.value = [];
  articlePoolUsed.value = [];
  articlePoolVertical = {};
  queue.value = [];
  isFetching = false;
  isPopulating = false;
  offset = 0;
}, 250);

// Add up the total count and required verticals count and add some buffer since we are not asking for
// specifics on the featured, highlighted, editors choice, and other filters
const getTotals = () => {
  let count = 0;
  const requiredVerticals: RequiredVerticals = {};

  get(queue).forEach((it) => {
    count += it.params.count * bufferMultiplier;

    if (it.params.requiredVerticals) {
      Object.keys(it.params.requiredVerticals).forEach((key: string) => {
        if (!requiredVerticals[key]) {
          requiredVerticals[key] = 0;
        }

        requiredVerticals[key] += it.params.requiredVerticals[key] * bufferMultiplier;
      });
    }
  });

  return { count, requiredVerticals };
};

const regenerateArticlePoolVertical = () => {
  articlePoolVertical = {};
  get(articlePool).forEach((article, i) => {
    const vertLower = article.vertical.toLowerCase();
    if (typeof articlePoolVertical[vertLower] === 'undefined') {
      articlePoolVertical[vertLower] = [];
    }

    articlePoolVertical[vertLower].push(i);
  });
};

const getArticles = () => {
  const { count, requiredVerticals } = getTotals();
  // @ts-ignore
  return api.getArticles({
    contentType: [ 'Article', 'Video', 'Podcast' ],
    offset,
    rows: count,
    requiredVerticals,
  })
    .then((res: any) => res?.data?.result || [])
    .then((res: Array<any>) => {
      const results = structuredClone(res);
      set(articlePool, get(articlePool).concat(results));
      regenerateArticlePoolVertical();
      offset += count;
    });
};

const onQueueChange = useDebounceFn(async (forceFetch: boolean = false) => {
  if (forceFetch) {
    get(queue).forEach((it) => {
      // We don't want to have runaways where it keeps trying to fetch
      if (it.fetchCount > 5) {
        forceFetch = false;
      }
      it.fetchCount++;
    });
  }

  if (forceFetch || (!isFetching && !get(articlePool).length)) {
    isFetching = true;
    await getArticles();
    isFetching = false;
  }

  if (!get(queue).length) {
    // Nothing in the queue, no need to continue
    return;
  }

  populateQueueItems(); // eslint-disable-line
}, 1000);

const shouldSkip = (filter: any, articleValue: string|string[]) => {
  if (typeof filter === 'undefined' || filter === null) return false;

  if (Array.isArray(articleValue)) {
    for (let i = 0; i < articleValue.length; i++) {
      articleValue[i] = articleValue[i].toLowerCase();
    }
  } else {
    articleValue = articleValue?.toLowerCase?.() || articleValue;
  }

  if (Array.isArray(filter) && !Array.isArray(articleValue)) {
    return !filter.includes(articleValue);
  }

  // This would be things such as tags or series where there are multiple and we're looking to match at least 1
  if (!Array.isArray(filter) && Array.isArray(articleValue)) {
    return !articleValue.includes(filter);
  }

  if (typeof articleValue === 'undefined' && filter === false) return false;

  return filter !== articleValue;
};

const skipCheck = (article: any, {
  featured, highlighted, editorsChoice, formats, verticals, tags, series,
}: Input) => (
  shouldSkip(featured, article?.featured)
    || shouldSkip(highlighted, article?.highlighted)
    || shouldSkip(editorsChoice, article?.editorsChoice)
    || shouldSkip(formats, article?.format)
    || shouldSkip(verticals, article?.vertical)
    || shouldSkip(tags, article?.tags)
    || shouldSkip(series, article?.series)
);

const populateRequestCleanup = (remove: number[], articles: any[], request: QueueItem) => {
  remove.sort((a, b) => b - a); // Sort largest to smallest since we could have gotten out of order with requiredVerticals
  remove.forEach((pos) => {
    get(articlePool).splice(pos, 1);
  }); // Clear out the used articles from the pool
  regenerateArticlePoolVertical();
  // @ts-ignore
  articles.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
  request.resolve(articles);
  return true;
};

// Fetch the exact set of articles based on the filters excluding articles that were already used and match
const getExactArticles = async (request: QueueItem, articles: any[] = [], remove: number[] = [], options: Options = {}) => {
  const { count } = request.params;
  const exclude: any[] = Array.isArray(options.exclude) ? options.exclude : [];
  get(articlePoolUsed).forEach((article: any) => {
    if (!skipCheck(article, request.params)) {
      exclude.push(article.uuid);
    }
  });

  // @ts-ignore
  return api.getArticles({ ...request.params, rows: request.params.count, exclude })
    .then((res: any) => res?.data?.result || [])
    .then((res: Array<any>) => {
      const results = structuredClone(res);

      for (let i = 0; i < results.length; i++) {
        articles.push(results[i]);

        if (articles.length === count) {
          return populateRequestCleanup(remove, articles, request);
        }
      }
      return populateRequestCleanup(remove, articles, request);
    });
};

// Try to completely populate a request.  If it is full then resolve it and return true otherwise return false
const populateRequest = async (request: QueueItem) => {
  const { count, requiredVerticals } = request.params;
  const articles: any[] = [];
  const remove: number[] = [];

  if (!count) {
    console.error('Failed to provide a count.', request.params); // eslint-disable-line
  }

  // Populate the required verticals first
  const rvKeys = requiredVerticals ? Object.keys(requiredVerticals) : [];
  for (let i = 0; i < rvKeys.length; i++) {
    const vertical = rvKeys[i];

    if (!articlePoolVertical[vertical]) {
      continue;
    }

    for (let j = 0; j < articlePoolVertical[vertical].length; j++) {
      const articlePosition = articlePoolVertical[vertical][j];
      const article = get(articlePool)[articlePosition];

      // Skip if don't match all filters
      if (!article || skipCheck(article, request.params)) {
        continue;
      }

      articles.push(article);
      remove.unshift(articlePosition); // Put them in backwards so that we remove from end to beginning below
      requiredVerticals[vertical]--;

      get(articlePoolUsed).push(get(article));

      if (articles.length === count) {
        return populateRequestCleanup(remove, articles, request);
      }

      // We only want as many as are required for a given vertical then move on to the next vertical
      if (requiredVerticals[vertical] <= 0) {
        break;
      }
    }
  }

  // Populate the remaining from the article pool
  for (let i = 0; i < get(articlePool).length; i++) {
    // If we already added it during requiredVerticals then skip
    if (remove.includes(i)) continue;

    const article = get(articlePool)[i];

    // Skip if don't match all filters
    if (skipCheck(article, request.params)) {
      continue;
    }

    articles.push(article);
    remove.unshift(i); // Put them in backwards so that we remove from end to beginning below

    get(articlePoolUsed).push(get(article));

    if (articles.length === count) {
      return populateRequestCleanup(remove, articles, request);
    }
  }

  // We don't have what we need, fetch the exact set of articles based on the filters excluding
  // articles that were already used and match
  const res = await getExactArticles(request, articles, remove);
  if (res) return res;

  return false;
};

// Loop over the queues and try to populate in the order we have received them, remove from queue when resolved
function populateQueueItems() {
  if (isPopulating) return;

  isPopulating = true;
  let allFullfilled = true;

  queue.value = get(queue).filter((request) => {
    const res = populateRequest(request);
    if (!res) allFullfilled = false;
    return res;
  });

  if (!allFullfilled) {
    // Force a fetch to fullfill all of the remaining requests
    onQueueChange(true);
  }

  isPopulating = false;
}

const newRequest = (params: Input, options: Options = {}) => {
  if (options.skipPool) {
    return new Promise((resolve, reject) => {
      const request = {
        params: structuredClone(params),
        resolve,
        reject,
        fetchCount: 0,
      };

      getExactArticles(request, [], [], options);
    });
  }

  return new Promise((resolve, reject) => {
    queue.value.push({
      params: structuredClone(params),
      resolve,
      reject,
      fetchCount: 0,
    });
    onQueueChange();
  });
};

export function useFetchArticles(params: Input, options: Options) { // eslint-disable-line
  const data = ref([]);
  const error = ref(null);
  const loaded = ref(false);

  onBeforeRouteLeave(() => {
    throttledRouteChange();
  });

  if (params.requiredVerticals) {
    params.requiredVerticals = toValue(params.requiredVerticals);
  }

  newRequest(params, options)
    // @ts-ignore
    .then((articles: Array<any>) => {
      set(data, articles);
      set(loaded, true);
    })
    .catch((err: Error) => {
      set(error, err);
      set(loaded, true);
    });

  return { articles: data, error, loaded };
}
