<template>
  <div class="latest-content" :data-testid="componentID()">
    <div class="header">
      <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
        {{ title }}
      </h2>
      <!--Desktop See All Link-->
      <router-link
        v-if="isDesktop && seeMore"
        :to="seeMore"
        :aria-label="`See All ${title}`"
        class="martech-see-all-link see-all-mobile">
        See All
      </router-link>
    </div>
    <div class="latest-content--grid">
      <!-- placeholder component -->
      <div v-if="!orderedArticles.length && view === 'box'" class="box-placeholder">
        <small-card-placeholder v-for="p in rows" :key="p" class="item"/>
      </div>
      <!--latest content component -->
      <!--Card Row Style-->
      <card-grid
        v-if="view === 'grid'"
        :articles="orderedArticles"
        :on-click="handleClick"
        :column-count="columnCount"
        :show-vertical-link="showVerticalLink"
        :include-promos="false"
        :show-date="showDate"
        :row-count="gridRowCount"/>
      <!-- Box List Style-->
      <box-view v-else-if="view === 'box'" :articles="orderedArticles" :large="true" :on-click="handleClick" :show-tag="true" :show-read-time="true" :show-author="true"/>
      <box-view
        v-if="view === 'box-large'"
        :articles="orderedArticles"
        :on-click="handleClick"
        :large="true"
        :show-tag="false"
        :show-author="true"
        :show-date="true"
        :show-format="true"
        :show-read-time="true"/>
      <!--Mobile See All Link-->
      <base-button
        v-if="isMobile && seeMore"
        class="see-all-button"
        btn-style="martech-primary"
        btn-size="martech-small"
        @clicked="seeMoreNavigate">
        See All
      </base-button>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, watch } from 'vue';
import CardGrid from '@/components/article-list/CardGrid.vue';
import BoxView from '@/components/article-list/BoxView.vue';
import SmallCardPlaceholder from '@/components/placeholder-components/SmallCardPlaceholder.vue';
import { useDeviceType, useAffinityOrderData, BaseButton } from '@tcgplayer/martech-components';
import amplitudeEvent from '@tcgplayer/amplitude';
import { useFetchArticles } from '@/use/fetcher/useFetchArticles';
import { useUser } from '@/use/useUser';
import { useRouter } from 'vue-router';

const props = defineProps({
  verticalName: {
    type: String,
    default: '',
  },
  contentType: {
    type: Array,
    default: () => [ 'Article', 'Video', 'Podcast', ],
  },
  rows: {
    type: Number,
    default: 4,
  },
  columnCount: {
    type: Number,
    default: 4,
  },
  view: {
    type: String,
    default: 'grid',
  },
  showVerticalLink: {
    type: Boolean,
    default: false,
  },
  showDate: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: '',
  },
  seeMore: {
    type: [ String, Object ],
    default: '',
  },
  editorsChoice: {
    type: Boolean,
    default: null,
  },
  promoPosition: {
    type: Number,
    default: null,
  },
  placement: {
    type: String,
    default: 'Homepage',
  },
  onClick: {
    type: Function,
    default: (article) => {},
  },
  uniqueContent: {
    type: String,
    default: null,
  },
  uniqueResultsField: {
    type: String,
    default: 'uuid',
  },
  featured: {
    type: Boolean,
    default: null,
  },
  highlighted: {
    type: Boolean,
    default: null,
  },
  gridRowCount: {
    type: Number,
    default: 1,
  },
  requiredVerticals: {
    type: Object,
    default: null,
  },
  skipArticlePool: {
    type: Boolean,
    default: false,
  },
  tags: {
    type: String,
    required: false,
    default: '',
  },
  series: {
    type: String,
    required: false,
    default: '',
  },
});

const { isMobile, isDesktop } = useDeviceType();
const { productLineAffinity } = useUser();
const router = useRouter();

const emit = defineEmits([ 'item-count' ]);

const { articles } = useFetchArticles({
  count: props.rows,
  verticals: props.verticalName || null,
  featured: props.featured,
  highlighted: props.highlighted,
  editorsChoice: props.editorsChoice,
  series: props.series,
  tags: props.tags,
  requiredVerticals: props.requiredVerticals,
}, {
  skipPool: props.skipArticlePool,
});

watch(articles, () => {
  emit('item-count', articles.value.length);
});

const orderedArticles = computed(() => {
  const { orderedData } = useAffinityOrderData({
    data: articles.value,
    affinity: productLineAffinity || undefined,
    positions: [ 0 ],
  });

  return orderedData.value;
});

const handleClick = (article) => {
  const data = {
    title: article.title,
    productLine: article.vertical,
    format: article?.format || '',
    url: article.canonicalURL,
    source: 'Latest Content',
    author: article.authorName,
  };

  if (article.date || article.dateTime) {
    try {
      data.datePublished = new Date(article.dateTime || article.date).toISOString();
    } catch (_) { /* Just in case */ }
  }

  amplitudeEvent('infinite', 'infiniteArticleClicked', data);

  props.onClick(article);
};

const seeMoreNavigate = () => router.push({ path: props.seeMore });
</script>

<style lang="scss" scoped>
.latest-content {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $martech-spacer-2;
    min-height: 35px;
  }

  :deep(.martech-button) {
    margin-top: $martech-spacer-3;
    width: 100%;
  }
}
</style>
