<!--This component contains all the child components that makeup the deck embed-->
<template>
  <div :data-testid="componentID()">
    <delay-load @visible="onVisible">
      <martech-deck-embed
        v-if="render"
        :deck-id="deckId"
        :deck-data="deckData"
        :vertical-name="deckVertical"
        :maindeck-quantity="maindeckQuantity"
        :sub-deck="subDeck"
        :sort-deck="sort"
        :analytics-data="analyticsData"
        :on-change="onSortChange"
        :active-card="activeCard"
        :set-active-card-callback="setActiveCard"
        :set-active-modal="setActiveCardModal"
        :active-card-url="activeCardProductUrlUtm"
        :page-type="pageType"
        @card-buy-click-event="sendClickEvent"
        @buy-deck-event="sendBuyDeckClickEvent"
        @magnify="magnifyImage"/>
      <desktop-magnify-image
        v-if="activeCard && isImageMagnified"
        :card-data="activeCard"
        :analytics-data="analyticsData"
        :card-product-url="activeCardProductUrlUtm"
        loaded
        :is-flipped="activeCard.isFlipped"
        @close="isImageMagnified = false"
        @flip="activeCard.isFlipped = !activeCard.isFlipped"/>
      <mobile-product-details-sheet
        v-if="activeCard"
        :analytics-data="analyticsData"
        :card-data="activeCard"
        :card-product-url="activeCardProductUrlUtm"
        loaded
        :show="openModal"
        :is-flipped="activeCard.isFlipped"
        @clicked="sendClickEvent"
        @flip="activeCard.isFlipped = !activeCard.isFlipped"
        @close="closeMobileModal"/>
    </delay-load>
    <placeholder v-if="!render && !error" :image-height="'950px'" :placeholder-height="'1000px'"/>
    <div v-else-if="!render && error" class="deck-alert">
      <alert
        type="danger"
        rounded-corners
        centered-text
        alert-title="Deck Not Found"
        alert-text="Sorry, this deck is no longer available or may have been deleted."/>
    </div>
  </div>
</template>

<script>
import delve from 'dlv';
import { mapState } from 'vuex';
import amplitudeEvent from '@tcgplayer/amplitude';
import {
  Alert,
  MartechDeckEmbed,
  VerticalHelpers as verts
} from '@tcgplayer/martech-components';
import Api from '@/api/api';
import time from '@/time';
import DelayLoad from '@/components/DelayLoad.vue';
import DesktopMagnifyImage from '@/components/product-details/DesktopMagnifyImage.vue';
import MobileProductDetailsSheet from '@/components/product-details/MobileProductDetailsSheet.vue';
import Placeholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import deviceType from '@/mixins/deviceType';
import url from '@/lib/url';
import decks from '@/lib/decks';

export default {
  name: 'deck-embed',
  components: {
    DelayLoad,
    DesktopMagnifyImage,
    MobileProductDetailsSheet,
    Placeholder,
    Alert,
    MartechDeckEmbed,
  },
  mixins: [ deviceType ],
  props: {
    deckId: {
      type: String,
      required: true,
      default: '',
    },
    deckVertical: {
      type: String,
      required: true,
      default: 'Magic',
    },
    isExternalId: {
      type: String,
      required: false,
      default: 'false',
    },
    isDeepLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    trackDeckView: {
      type: Boolean,
      required: false,
      default: false,
    },
    pageType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      deckData: null,
      activeCard: null,
      isImageMagnified: false,
      openModal: false,
      render: false,
      tcgPlayerID: '',
      lastToggle: time.now(),
      sort: 'overview',
      error: false,
      fromPath: '',
      cards: {},
    };
  },
  computed: {
    ...mapState('article', [ 'article', 'author', 'analytics' ]),
    analyticsSource() {
      return process.env.VUE_APP_NAME_ANALYTICS;
    },
    analyticsData() {
      // If the route is not articlePage campaign should be deck-landing
      if (!this.$route.meta.articlePage) {
        return { analyticsCampaign: 'deck-landing', analyticsSource: this.analyticsSource, analyticsMedium: 'deck-embed' };
      }

      return this.analytics;
    },
    skillcard() { return this.subDeck('skillcard'); },
    commandzone() { return this.subDeck('commandzone'); },
    extradeck() { return this.subDeck('extradeck'); },
    maindeck() { return this.subDeck('maindeck'); },
    sideboard() { return this.subDeck('sideboard'); },
    sidedeck() { return this.subDeck('sidedeck'); },
    title() {
      if (this.isDeepLink) {
        return this.deckData.deckName;
      }

      return this.articleTitle;
    },
    maindeckQuantity() {
      return Object.values(this.maindeck.cards).reduce((sum, card) => {
        // FAB doesn't include Hero in total count
        if (card.game === 'flesh and blood' && card.types.includes('Hero')) return sum;

        return sum + card.quantity;
      }, 0);
    },
    activeCardProductUrlUtm() {
      return url.urlUtm(this.analyticsData, delve(this, 'activeCard.tcgProductURL', ''));
    },
  },
  created() {
    this.fromPath = this.$route.meta.fromPath;
  },
  methods: {
    async onVisible() {
      if (this.deckId) {
        await Api.getDeck(this.deckId, this.deckVertical.toLowerCase(), this.isExternalId === 'true')
          .then((response) => {
            this.deckData = decks.parseDeckAPIResponse(response, this.deckId, this.article, this.author);

            this.render = delve(this.deckData.subDecks, 'maindeck') && Object.keys(this.deckData.subDecks.maindeck).length > 0;

            if (this.trackDeckView) {
              amplitudeEvent('infinite', 'infiniteDeckView', {
                name: delve(response, 'data.result.deck.name', ''),
                format: delve(response, 'data.result.deck.format', ''),
                fromPath: this.fromPath,
                player: delve(response, 'data.result.deck.playerName', ''),
                vertical: delve(response, 'data.result.deck.game', ''),
                dateCreated: delve(response, 'data.result.deck.created', ''),
                isAdmin: delve(response, 'data.result.deck.isAdmin', ''),
                facebookBoost: delve(this, '$route.query.facebookBoost') === 'true',
                source: delve(this, '$route.query.source'),
              });
            }
          }).catch((error) => {
            this.error = true;
          });
      }

      if (!this.deckData) {
        return;
      }

      // Sets maindeck active
      if (this.deckData.subDecks.maindeck) {
        let found = false;

        for (let i = 0; i < this.deckData.subDecks.maindeck.length; i++) {
          if (this.deckData.subDecks.maindeck[i]) {
            const card = this.deckData.subDecks.maindeck[i];

            if ((card.types && (card.types[card.types.length - 1] === 'Creature' || card.types[card.types.length - 1] === 'Spell'))
          || (card.cardType && (card.cardType[card.cardType.length - 1] === 'Monsters' || card.cardType[card.cardType.length - 1] === 'Spells'))
          || (card.types && (card.types.includes('Hero')))) {
              this.setActiveCard(card);
              found = true;
              break;
            }
          }
        }

        // Sets first card in maindeck as active if no creature/spell/monsters/spells/hero are found in magic, yugioh, or flesh and blood
        if (this.deckData.subDecks.maindeck.length > 0 && !found) {
          this.setActiveCard(this.deckData.subDecks.maindeck[0]);
        }
      }

      // Sets Yugioh skill card as active
      if (this.deckData.game === 'yugioh' && this.deckData.subDecks.skillcard) {
        const active = this.deckData.subDecks.skillcard[0];
        if (active) { this.setActiveCard(active); }
      }

      // Sets Magic Commander card as active
      if (this.deckData.game === 'magic' && this.deckData.subDecks.commandzone) {
        const active = this.deckData.subDecks.commandzone[0];
        if (active) { this.setActiveCard(active); }
      }

      this.render = true;
    },
    onSortChange(key, val) {
      this.sort = key;
      amplitudeEvent('infinite', 'infiniteDeckSort', {
        id: this.deckId,
        name: this.deckData.name,
        game: this.deckData.game,
        format: this.deckData.format,
        embed: delve(this, 'article.canonicalURL') ? 'article' : '',
        embedCanonical: delve(this, 'article.canonicalURL', ''),
        sortType: key,
      });
    },
    sendClickEvent() {
      amplitudeEvent('martech', 'buyButton', {
        component: this.$options.name,
        title: delve(this, 'article.title'),
        type: 'card',
        name: this.activeCard.name,
        game: verts.displayName(this.deckData.game),
        utm_source: this.analyticsSource,
        itm_source: this.analyticsSource,
        author: delve(this, 'author.name'),
        date_published: delve(this, 'article.dateTime'),
        format: delve(this, 'article.format'),
        tags: delve(this, 'article.tags'),
      });
    },
    sendBuyDeckClickEvent() {
      amplitudeEvent('martech', 'buyButton', {
        component: this.$options.name,
        title: delve(this, 'article.title'),
        type: 'deck',
        name: this.deckData.name,
        game: verts.displayName(this.deckData.game),
        utm_source: this.analyticsSource,
        itm_source: this.analyticsSource,
        author: delve(this, 'author.name'),
        date_published: delve(this, 'article.dateTime'),
        format: delve(this, 'article.format'),
        tags: delve(this, 'article.tags'),
      });
    },
    magnifyImage() {
      this.isImageMagnified = true;
    },
    setActiveCard(card) {
      if (!card.game) {
        card.game = delve(this, 'deckData.game', '');
      }
      this.activeCard = card;
    },
    setActiveCardModal() {
      this.toggle();
    },
    toggle() {
      const now = time.now();
      if ((now - this.lastToggle) < 50) {
        return;
      }

      this.openModal = true;
      this.lastToggle = time.now();
    },
    closeMobileModal() {
      this.openModal = false;
      this.activeCard = '';
    },
    sortByName(a, b) {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    },
    subDeck(name) {
      return {
        article: this.article,
        author: this.author,
        createdBy: this.deckData.createdBy,
        deckID: this.deckData.id,
        external: this.isExternalId,
        game: this.deckData.game,
        format: this.deckData.format,
        name,
        cards: this.deckData.subDecks[name],
        setActiveCard: this.setActiveCard,
        setActiveModal: this.setActiveCardModal,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-alert {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
