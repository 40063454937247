<template>
  <div v-show="display" ref="cardRef" class="animated-card" :data-testid="componentID()">
    <div class="shadow-wrap">
      <div v-if="header.date || header.eventType" ref="cardHeader" class="card-header" :style="{'background-color': headerColor}">
        <p v-if="header.date">
          {{ header.date }}
        </p>
        <p v-if="header.eventType">
          {{ header.eventType }}
        </p>
      </div>
      <div ref="bodyRef" class="card-body">
        <h2 v-if="body.title" ref="titleRef" class="card-title">
          {{ body.title }}
        </h2>

        <p ref="subtitleRef" class="card-subtitle">
          {{ body.subTitle }}
        </p>

        <img v-if="body.imageUrl" ref="imageRef" :src="body.imageUrl" :alt="body.imageAlt" class="card-image">

        <p ref="textRef" class="card-text">
          {{ body.text }}
        </p>
        <div v-if="groupSize > 1" ref="buttonRef" class="footer">
          <button class="pagination" :disabled="groupPosition <= 1" @click="$emit('pagination', 'previous')">
            <img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/arrow-left.svg" alt="previous card">
          </button>
          0{{ groupPosition }}/0{{ groupSize }}
          <button class="pagination" :disabled="groupPosition >= groupSize" @click="$emit('pagination', 'next')">
            <img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/arrow-right.svg" alt="next card">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  header: {
    type: Object,
    default: () => ({
      date: '',
      eventType: '',
      color: '',
    }),
  },
  body: {
    type: Object,
    default: () => ({
      title: '',
      subTitle: '',
      text: '',
      imageUrl: '',
      imageAlt: '',
    }),
  },
  alternateImage: {
    type: Object,
    default: () => ({
      imageUrl: '',
      imageAlt: '',
    }),
  },
  stStart: {
    type: String,
    default: undefined,
  },
  stEnd: {
    type: String,
    default: undefined,
  },
  stScrub: {
    type: String,
    default: undefined,
  },
  animate: {
    type: Boolean,
    default: true,
  },
  jitter: {
    type: Number,
    default: 0,
  },
  display: {
    type: Boolean,
    default: true,
  },
  groupSize: {
    type: Number,
    default: 1,
  },
  groupPosition: {
    type: Number,
    default: 1,
  },
});

const cardRef = ref(null);
const cardHeader = ref(null);
const bodyRef = ref(null);
const titleRef = ref(null);
const subtitleRef = ref(null);
const imageRef = ref(null);
const textRef = ref(null);
const buttonRef = ref(null);

const headerColor = computed(() => {
  switch (props.header?.color) {
    case 'orange':
      return '#FFBE5C';
    case 'mint':
      return '#9BFFB7';
    case 'light blue':
      return '#B9FFFB';
    case 'purple':
      return '#D5B5FF';
    case 'chartreuse':
      return '#E2FF30';
    default:
      return '#FF8A98';
  }
});

onMounted(() => {
  if (!props.animate) return;

  // Animation when scrolling up
  const tlReverse = gsap.timeline({
    scrollTrigger: {
      trigger: cardRef.value,
      start: 'top 35%',
      markers: false,
      scrub: false,
      pin: false, // pin true will mess with the timeline unless we pin the timeline at the same time
      once: false,
      toggleActions: 'reset none none play',
    },
  });

  tlReverse.to(cardRef.value, {
    opacity: 0,
    duration: 0.25,
    ease: 'power2.inOut',
    display: 'none',
  });

  // Animation when scrolling down
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: cardRef.value,
      start: 'top 40%',
      markers: false,
      scrub: false,
      pin: false, // pin true will mess with the timeline unless we pin the timeline at the same time
      once: false,
      toggleActions: 'restart pause none none',
    },
  });

  tl.from(cardRef.value, {
    x: -300, opacity: 0, duration: 0.15, stagger: 0, ease: 'none', transformOrigin: 'left',
  });

  tl.to(cardRef.value, {
    x: 25, duration: 0.12, ease: 'elastic.out(0.5, 0.3)',
  });

  tl.to(cardRef.value, {
    x: props.jitter, duration: 0.25, ease: 'bounce.easeOut',
  });

  tl.to(cardRef.value, {
    opacity: 1, duration: 0.04, ease: 'elastic.out(0.5, 0.3)',
  });

  tl.from(cardHeader.value, {
    height: '10px',
  }, '>-0.5');

  tl.to(cardHeader.value, {
    height: 'auto', duration: 0.1, ease: 'elastic.out(0.4, 0.3)',
  });

  tl.from(bodyRef.value, {
    height: '0px', duration: 0.25, ease: 'none', transformOrigin: 'top',
  }, '>-0.5');

  tl.to(bodyRef.value, {
    height: 'auto', opacity: 1, duration: 0.1, ease: 'elastic.out(0.4, 0.3)',
  });

  tl.from(titleRef.value, {
    y: -20, opacity: 0, duration: 0.15, ease: 'none',
  }, '>-0.5');

  tl.to(titleRef.value, {
    y: 0, duration: 0.08, ease: 'elastic.out(0.4, 0.3)',
  }, '>-1');

  tl.to(titleRef.value, {
    opacity: 1, duration: 0.3, ease: 'elastic.out(0.4, 0.3)',
  });

  tl.from(subtitleRef.value, {
    y: -40, opacity: 0, duration: 0.08, ease: 'none',
  }, '>-0.65');

  tl.to(subtitleRef.value, {
    y: 0, duration: 0.15, ease: 'elastic.out(0.4, 0.3)',
  }, '>-1');

  tl.to(subtitleRef.value, {
    opacity: 1, duration: 0.3, ease: 'elastic.out(0.4, 0.3)',
  });

  tl.from(imageRef.value, {
    y: -20, opacity: 0,
  }, '>-0.95');

  tl.to(imageRef.value, {
    y: 0, duration: 0.08, ease: 'elastic.out(0.4, 0.3)',
  }, '>-0.1');

  tl.to(imageRef.value, {
    opacity: 1, duration: 0.2, delay: 0.5, ease: 'power1.out',
  });

  tl.from(textRef.value, {
    y: -40, opacity: 0, duration: 0.15, ease: 'none',
  }, '>-1.5');

  tl.to(textRef.value, {
    y: 0, duration: 0.18, ease: 'elastic.out(0.4, 0.3)',
  }, '>-0.75');

  tl.to(textRef.value, {
    opacity: 1, duration: 0.2, ease: 'elastic.out(0.4, 0.3)',
  });

  tl.from(buttonRef.value, {
    y: -100, opacity: 0, duration: 0.1, ease: 'none',
  }, '>-2');

  tl.to(buttonRef.value, {
    y: 0, opacity: 1, duration: 0.12, ease: 'elastic.out(0.4, 0.3)',
  });
});
</script>

<style lang="scss" scoped>
.animated-card {
  font-family: 'Source Code Pro', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  width: 100%;
  height: auto !important;
  padding: $martech-spacer-3;
  position: relative;
  z-index: 20;

  .shadow-wrap {
    box-shadow: 0px 12px 32px 2px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    padding: $martech-spacer-2 $martech-spacer-3;
    font-weight: $martech-weight-semibold;
    border-bottom: 1px solid $martech-black;
    font-size: $martech-type-14;
    line-height: 21.6px;
  }

  .card-body {
    background: $martech-white;
    overflow: hidden;

    .card-title {
      font-weight: $martech-weight-bold;
      font-size: $martech-type-22;
      line-height: 29.7px;
      padding: $martech-spacer-3  $martech-spacer-3 0  $martech-spacer-3;
    }

    .card-subtitle {
      font-size: $martech-type-14;
      color: $martech-text-subdued;
      margin-bottom: $martech-spacer-3;
      line-height: 24.5px;
      padding: 0 $martech-spacer-3;
    }

    .card-text {
      font-size: $martech-type-16;
      line-height: 28px;
      padding: 0 $martech-spacer-3 $martech-spacer-3 $martech-spacer-3;
    }

    .card-image {
      width: 85%;
      display: block;
      margin: auto;
      padding: 0 $martech-spacer-3 $martech-spacer-3 $martech-spacer-3;
    }

    .footer {
      font-size: $martech-type-16;
      display: flex;
      justify-content: flex-end;
      padding: 0 $martech-spacer-3 $martech-spacer-3 $martech-spacer-3;

      .pagination {
        background-color: transparent;
        img {
          width: 12px;
        }
      }
    }
  }
}
</style>
