<template>
  <on-enter-navigate-card :data="article">
    <router-link :to="article.canonicalURL || '#'" :data-testid="componentID()" tabindex="-1" :role="authorHasFocus ? 'none' : 'link'">
      <div ref="root" class="content-wrapper" :class="{'desktop' : deviceType === 'desktop'}" tabindex="-1">
        <div :aria-hidden="authorHasFocus" class="card-image" :class="[{'large' : large && deviceType === 'desktop'}, {'includes-author' : showAuthor}]" tabindex="-1">
          <right-text-overlay v-if="showTag && deviceType === 'desktop'" text="New"/>
          <img v-if="hasRoot" :src="imageURL(article.imageURL || fallbackImage, { maxDimensions: 'autoxauto', quality: 75, })" alt="" @error="errorImageFallback($event)">
          <div v-if="contentType === 'Video'" class="play-icon">
            <video-play-icon/>
          </div>
          <format-tag v-if="showReadTime && deviceType === 'desktop'" read-time :article-read-time="article.readTime" :content-type="contentType" :format="format"/>
        </div>
        <div class="card-content" :aria-hidden="authorHasFocus">
          <p v-show="showVertical" class="martech-labels martech-blue">
            {{ verticalDisplay }}
          </p>
          <h4 class="martech-body martech-barlow martech-three-lines">
            {{ article.title }}
          </h4>
          <div class="card-footer">
            <div v-if="showAuthor" class="card-author">
              <router-link
                v-if="article.authorName"
                tabindex="0"
                :to="article.authorURL"
                class="article-card--author"
                @focus="authorHasFocus = true"
                @blur="authorHasFocus = false"
                @keyup.enter.stop>
                <author-picture-template
                  :aria-hidden="!authorHasFocus"
                  :source="article.authorImageURL"
                  :image-alt-name="`Picture of ${article.authorName}`"
                  size="small"/>
                <p class="martech-text-sm" :aria-hidden="!authorHasFocus">
                  {{ article.authorName }}
                </p>
              </router-link>
            </div>
            <p v-show="displayDate" class="martech-text-sm date">
              {{ article.date }}
            </p>
          </div>
        </div>
      </div>
    </router-link>
  </on-enter-navigate-card>
</template>

<script>
import deviceType from '@/mixins/deviceType';
import AuthorPictureTemplate from '@/components/templates/AuthorPictureTemplate.vue';
import showNewTag from '@/mixins/showNewTag';
import imageAlter from '@/mixins/image/alter';
import RightTextOverlay from '@/components/card-elements/RightTextOverlay.vue';
import FormatTag from '@/components/card-elements/FormatTag.vue';
import { ImageHelper as image, VerticalHelpers as verts } from '@tcgplayer/martech-components';
import OnEnterNavigateCard from '@/components/card-elements/OnEnterNavigateCard.vue';
import VideoPlayIcon from '@/components/iconography/VideoPlayIcon.vue';
import delve from 'dlv';

export default {
  name: 'card-box-article',
  components: {
    AuthorPictureTemplate,
    RightTextOverlay,
    FormatTag,
    OnEnterNavigateCard,
    VideoPlayIcon,
  },
  mixins: [ showNewTag, deviceType, imageAlter ],
  props: {
    article: {
      type: Object,
      required: true,
      default: null,
    },
    placement: {
      type: String,
      default: 'Article Page',
      required: false,
    },
    contentType: {
      type: String,
      default: 'Author',
      required: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    showAuthor: {
      type: Boolean,
      default: false,
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    showFormat: {
      type: Boolean,
      default: false,
    },
    showTag: {
      type: Boolean,
      default: false,
    },
    showVertical: {
      type: Boolean,
      default: false,
    },
    showReadTime: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authorHasFocus: false,
    };
  },
  computed: {
    displayDate() {
      return delve(this, '$route.meta.articlePage') || this.showDate;
    },
    format() {
      return (this.showFormat && this.article.format) || '';
    },
    fallbackImage() {
      return image.getFallbackImage(this.article.vertical, 'card');
    },
    verticalDisplay() {
      return verts.displayName(this.article.vertical.toLowerCase());
    },
  },
  methods: {
    errorImageFallback(event) {
      event.target.src = this.fallbackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: $martech-text-primary;
  background-color: $martech-white;
  .desktop {
    min-height: 121px;
  }

  @include breakpoint(1024) {
    min-height: 104px;
  }

  @include breakpoint(1200) {
    min-height: 137px;
  }

  .card-image {
    position: relative;
    .desktop {
      height: 121px;
    }

    @include breakpoint(1024) {
      height: 104px;

      &.includes-author {
        height: 114px;
      }
    }

    @include breakpoint(1200) {
      height: 137px;

      &.includes-author {
        height: 137px;
      }
    }

    &.large {
      height: 124px;

      img {
        width: 240px;
      }
    }

    img {
      width: 104px;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      border-top-left-radius: $martech-radius-medium;
      border-bottom-left-radius: $martech-radius-medium;
    }

    :deep(.format-tag) {
      border-bottom-left-radius: $martech-radius-medium;
    }

    :deep(.new-tag) {
      height: 24px;
      width: 65px;
      border-top-right-radius: 0;

      p {
        font-size: 13px;
      }
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    padding-left: $martech-spacer-3;
    width: 80%;

    h4 {
      .desktop {
        min-height: 72px;
      }
    }

    .card-author {
      p {
        padding-left: $martech-spacer-2;
      }

      a {
        display: flex;
        align-items: center;
        color: $martech-text-primary;
      }

      :deep(.small) {
        width: 32px;
        height: 32px;
      }
    }

    .card-footer {
      display: flex;
      flex-direction: column-reverse;
      padding: $martech-spacer-2 0;

      .date {
        padding: $martech-spacer-2 0;

        @include breakpoint(1024) {
          padding: 0;
        }
      }

      @include breakpoint(1024) {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
      }
    }
  }
}
</style>
