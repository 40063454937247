<template>
  <div ref="root" class="image__wrapper" :class="authorPhotoSizes" :data-testid="componentID()">
    <img
      v-if="visible && hasRoot"
      class="image__item"
      :class="authorPhotoSizes"
      :src="imageURL(source || placeholderImage, { maxDimensions: '200x200', quality: 75, })"
      alt="">
  </div>
</template>

<script>
import delayLoad from '@/mixins/delayLoad';
import imageAlter from '@/mixins/image/alter';

export default {
  name: 'author-picture-template',
  mixins: [ delayLoad, imageAlter ],
  props: {
    source: {
      type: String,
      default: '',
      required: false,
    },
    size: {
      type: String,
      default: 'small',
      required: false,
    },
  },
  data() {
    return {
      dragon: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/Content-Site-Placeholder-Images_Author_Dragon.svg',
      kraken: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/Content-Site-Placeholder-Images_Author_Kraken.svg',
      phoenix: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/Content-Site-Placeholder-Images_Author_Phoenix.svg',
      placeholderImage: null,
    };
  },
  computed: {
    placeholderImages() {
      return [ this.dragon, this.kraken, this.phoenix ];
    },
    authorPhotoSizes() {
      switch (this.size) {
        case 'xx-large':
          return 'xx-large';
        case 'x-large':
          return 'x-large';
        case 'large':
          return 'large';
        case 'medium':
          return 'medium';
        case 'sm-md':
          return 'sm-md';
        case 'x-small':
          return 'x-small';
        default:
          return 'small';
      }
    },
  },
  created() {
    this.placeholderImage = this.randomImages(this.placeholderImages);
  },
  methods: {
    randomImages(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    width: 100%;
    transition: all 0.15s ease-in-out;
    object-fit: cover;
    object-position: top center;
    border-radius: 100%;
  }
}

.x-small {
  width: 18px;
  height: 18px;
}
.small {
  width: 28px;
  height: 28px;
}
.sm-md {
  width: 48px;
  height: 48px;
}
.medium {
  width: 65px;
  height: 65px;
}
.large {
  width: 88px;
  height: 88px;
}
.x-large {
  width: 120px;
  height: 120px;
}

.xx-large {
  width: 149px;
  height: 149px;
}
</style>
