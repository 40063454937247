<template>
  <on-enter-navigate-card :data="article">
    <router-link :to="article.canonicalURL || '#'" tabindex="-1" :data-testid="componentID()" @click="$emit('click', article)">
      <div ref="root" class="content-wrapper" :class="{'side-rail' : sideRail}">
        <div class="card-image">
          <img v-if="hasRoot" :src="imageURL(article.imageURL || fallbackImage, { maxDimensions: 'autoxauto', quality: 75, })" alt="" @error="errorImageFallback($event)">
        </div>
        <div v-if="showVertical" class="card-vertical">
          <p class="martech-labels martech-white">
            {{ getVerticalName }}
          </p>
        </div>
        <div class="card-content">
          <h4 class="martech-body martech-barlow martech-three-lines">
            {{ article.title }}
          </h4>
          <div class="card-footer">
            <div class="card-author">
              <router-link v-if="article.authorName" :to="article.authorURL" class="author martech-text-sm" tabindex="-1">
                By {{ article.authorName }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="martech-card-overlay"/>
      </div>
    </router-link>
  </on-enter-navigate-card>
</template>

<script>
import imageAlter from '@/mixins/image/alter';
import { ImageHelper as image, VerticalHelpers as verts } from '@tcgplayer/martech-components';
import OnEnterNavigateCard from '@/components/card-elements/OnEnterNavigateCard.vue';

export default {
  name: 'card-image-article',
  components: {
    OnEnterNavigateCard,
  },
  mixins: [ imageAlter ],
  props: {
    article: {
      type: Object,
      required: true,
      default: null,
    },
    sideRail: {
      type: Boolean,
      default: false,
    },
    showVertical: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getVerticalName() {
      return verts.displayName(this.article.vertical);
    },
    fallbackImage() {
      return image.getFallbackImage(this.article.vertical, 'card');
    },
  },
  methods: {
    errorImageFallback(event) {
      event.target.src = this.fallbackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: $martech-radius-medium;
  background-color: $martech-black;

  @include breakpoint(1200) {
    height: 178px;
  }

  .card-image {
    width: 100%;
    height: 100%;
    background-color: $martech-black;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }
  }

  .card-vertical {
    width: 100%;
    position: absolute;
    z-index: 20;
    top: 0;
    padding: $martech-spacer-3;
    text-shadow: 1px 1px 0 $martech-black, 0 0 16px $martech-black;
  }

  .card-content {
   width: 100%;
   position: absolute;
   z-index: 20;
   bottom: 0;
   padding: $martech-spacer-3;
   text-shadow: 1px 1px 0 $martech-black, 0 0 16px $martech-black;

   h4 {
     color: $martech-white;
     margin-bottom: $martech-spacer-2;
   }

   .author {
     color: $martech-white;
   }
  }
  .martech-card-overlay {
    height: 80%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1));
  }
}
</style>
