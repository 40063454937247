import { computed } from 'vue';
import { get } from '@vueuse/core';

export default function optimizelyExperiment() {
  const experiments = {
    spotlight: {
      qa: {
        experimentId: 26152030586,
        variantId: [
          {
            buyButtonColorAndText: 26150100507,
          }
        ],
      },
      staging: {
        experimentId: 26173170382,
        variantId: [
          {
            buyButtonColorAndText: 26163580495,
          }
        ],
      },
      production: {
        experimentId: 26160410527,
        variantId: [
          {
            buyButtonColorAndText: 26174970619,
          }
        ],
      },
    },
    articleCardDates: {
      qa: {
        experimentId: 28366450199,
        variantId: [
          {
            hideDate: 28425030034,
          }
        ],
      },
      staging: {
        experimentId: 28409280366,
        variantId: [
          {
            hideDate: 28350680389,
          }
        ],
      },
      production: {
        experimentId: 28201000065,
        variantId: [
          {
            hideDate: 28187870018,
          }
        ],
      },
    },
  };

  const loadOptimizely = () => {
    if (!window.optimizely) return;
    const state = window.optimizely.get('state');
    if (!state) return;
    state.getVariationMap();
  };

  const currentEnvironment = computed(() => process.env.VUE_APP_OPTIMIZELY_ENV || 'production');

  const optimizelyActiveExperiments = computed(() => {
    if (!window.optimizely) return [];
    const state = window.optimizely.get('state');
    if (!state) return [];
    return state.getActiveExperimentIds();
  });

  const activeVariantId = () => {
    const variantMap = Object.values(window.optimizely.get('state').getVariationMap());

    const variantId = variantMap.map(element => element.id);

    return variantId.toString();
  };

  //   Set up your experiment & variants in optimizely first so you have the proper codes
  const optimizelyVariant = (experiment, variant) => {
    const experimentId = get(experiments)?.[experiment]?.[get(currentEnvironment)]?.experimentId;
    if (!experimentId) return false;

    const active = get(optimizelyActiveExperiments);

    if (!active.includes(experimentId.toString())) return false;

    const variantIds = get(experiments)?.[experiment]?.[get(currentEnvironment)]?.variantId;

    if (!Array.isArray(variantIds) || !variantIds.length) {
      return false;
    }

    for (let i = 0; i < variantIds.length; i++) {
      if (Object.values(variantIds[i])[0].toString() === activeVariantId()) {
        if (!variant || Object.keys(variantIds[i])[0] === variant) {
          return variantIds[i];
        }
      }
    }

    return false;
  };

  const optimizelyPush = (ev) => {
    const active = get(optimizelyActiveExperiments);

    // Only push if there's an active expirement
    if (!active.length) return;
    window.optimizely.push(ev);
  };

  return {
    loadOptimizely,
    optimizelyVariant,
    activeVariantId,
    optimizelyPush,
  };
}
