<template>
  <div v-if="!shouldHide" class="topnews-carousel" :data-testid="componentID()">
    <div class="container" :class="{'mobile-scroll' : isMobile}">
      <div class="header">
        <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          {{ title }}
        </h2>
        <router-link
          v-if="seeMore"
          :to="seeMore"
          :aria-label="`See All ${title}`"
          class="martech-see-all-link">
          See All
        </router-link>
      </div>
      <div v-if="articles.length" class="carousel" :class="{'mobile-scroll' : isMobile}" :style="{'grid-template-columns' : `repeat(${rows}, 1fr)`}">
        <div v-for="article in articles" :key="article.canonicalURL" class="slide">
          <slim-article-card :data="article" :show-date="showDate" :show-vertical="true" @click="handleClick"/>
        </div>
      </div>
      <div v-else class="placeholder">
        <small-card-placeholder v-for="(_, index) in placeholders" :key="index" class="item"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { get } from '@vueuse/core';
import { useDeviceType } from '@tcgplayer/martech-components';
import SmallCardPlaceholder from '@/components/placeholder-components/SmallCardPlaceholder.vue';
import SlimArticleCard from '@/components/card-elements/SlimArticleCard.vue';
import amplitudeEvent from '@tcgplayer/amplitude';
import { useFetchArticles } from '@/use/fetcher/useFetchArticles';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  tags: {
    type: String,
    default: '',
  },
  vertical: {
    type: String,
    default: null,
  },
  seeMore: {
    type: String,
    default: '',
  },
  minItems: {
    type: Number,
    default: 1,
  },
  rows: {
    type: Number,
    default: 3,
  },
  featured: {
    type: Boolean,
    default: null,
  },
  showDate: {
    type: Boolean,
    default: true,
  },
  commanderColors: {
    type: Array,
    default: () => [],
  },
  skipArticlePool: {
    type: Boolean,
    default: false,
  },
  exclude: {
    type: String,
    default: '',
  },
});

const { isMobile, isDesktop } = useDeviceType();

const placeholders = computed(() => (get(isDesktop) ? 3 : 1));

const params = {
  count: props.rows,
  tags: props.tags,
};
if (props.featured !== null) params.featured = props.featured;
if (props.vertical) params.verticals = props.vertical;
if (props.commanderColors.length) params.commanderColors = props.commanderColors;

const { articles, loaded } = useFetchArticles(params, { skipPool: props.skipArticlePool, exclude: [ props.exclude ] });

const shouldHide = computed(() => get(loaded) && get(articles).length < props.minItems);

const handleClick = (article) => {
  if (article.stopImmediatePropagation) {
    return;
  }

  const data = {
    title: article.title,
    productLine: article.vertical,
    format: article?.format || '',
    url: article.canonicalURL,
    source: 'Tag Carousel',
    author: article.authorName,
  };

  if (article.date || article.dateTime) {
    try {
      data.datePublished = new Date(article.dateTime || article.date).toISOString();
    } catch (_) { /* Just in case */ }
  }

  amplitudeEvent('infinite', 'infiniteArticleClicked', data);
};
</script>

<style lang="scss" scoped>
.topnews-carousel {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $martech-spacer-3;
    padding-bottom: $martech-spacer-2;
    padding-right: $martech-spacer-3;

    @include breakpoint(1024) {
      padding-right: 0;
    }

    @include breakpoint(1200) {
      padding-left: 0;
    }
  }

  .container {
    &.mobile-scroll {
      padding: 0;
    }
  }

  .placeholder {
    height: 116px;
    display: flex;
    padding: 0;
  }

  .carousel {
    position: relative;
    display: inline-flex;
    width: 100%;

    @include breakpoint(1024) {
      display: grid;
      grid-gap: $martech-spacer-3;
    }

    &.mobile-scroll {
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      width: 100vw;
      margin: -32px 0;
      padding: 32px 0;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .slide {
        &:first-of-type {
          padding-left: $martech-spacer-3;
        }

        &:last-of-type {
          padding-right: $martech-spacer-3;
        }
      }

      :deep(.slim-article-card) {
        width: 325px;
        scroll-snap-align: center;

        @media only screen and (max-width: 320px) {
          width: 230px;
        }
      }
    }

    .slide {
      width: 100%;
      padding-right: $martech-spacer-3;

      &:last-of-type {
        padding-right: 0;
      }

      @include breakpoint(1024) {
        padding-right: 0;
      }
    }
  }
}
</style>
