<template>
  <div class="mobile-newsletter">
    <div class="mobile-newsletter--column">
      <div class="mobile-newsletter--copy" :class="siteConfig('global.darkMode') ? 'dark' : 'light'">
        <h2>{{ title }}</h2>
        <p>{{ text }}</p>
      </div>
    </div>
    <div v-show="!signupSuccess" class="mobile-newsletter--column">
      <div
        class="input-wrapper">
        <form @submit.prevent="subscribeUserToNewsletters">
          <tcg-input-text
            v-model="userName"
            size="sm"
            label="Email"
            hide-label
            :state="inputState"
            placeholder="Email"/>
          <p v-if="error" class="error-txt">
            {{ error }}
          </p>
          <base-button btn-size="martech-small" @clicked="subscribeUserToNewsletters">
            Sign Up
          </base-button>
        </form>
      </div>
      <div class="consent" :class="siteConfig('global.darkMode') ? 'dark' : 'light'">
        <default-checkbox v-if="requireCheck && euConsentMessage" @on-change="euConsentGiven = !euConsentGiven"/>
        <p class="consent-copy">
          {{ euConsentMessage }} <span><base-link :link-url="privacyPolicyUrl" new-window url-target="_blank" class="martech-sm-detail">
            Privacy Policy
          </base-link></span>
        </p>
      </div>
    </div>
    <subscribe-confirmation-toast
      mobile-view
      :confirmation-text="confirmationText"
      :theme="siteConfig('global.darkMode') ? 'dark' : 'light'"
      :class="signupSuccess ? 'is-active' : 'is-hidden'"/>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';
import { get, set } from '@vueuse/core';

import api from '@/api/api';
import amplitudeEvent from '@tcgplayer/amplitude';

import SubscribeConfirmationToast from '@/components/newsletters/SubscribeConfirmationToast.vue';
import TcgInputText from '@tcgplayer/design-system/src/components/InputText/InputText.vue';
import {
  BaseLink, DefaultCheckbox, BaseButton, EmailHelper as email, PageHelpers as page
} from '@tcgplayer/martech-components';

const props = defineProps({
  newsletterId: {
    type: String,
    required: false,
    default: '',
  },
});

const siteConfig = getCurrentInstance()?.appContext.config.globalProperties.siteConfig;

const userName = ref();
const error = ref();
const newsletterTextError = ref();
const text = ref();
const title = ref();
const confirmationText = ref();
const euConsentRequired = ref(true);
const euConsentGiven = ref(false);
const euConsentMessage = 'I consent to receive information about special offers by email from TCGplayer.';
const privacyPolicyUrl = 'https://tcgplayer.com/privacypolicy';
const requireCheck = ref(false);
const inputState = ref();
const signupSuccess = ref(false);

const getNewsletter = () => {
  if (!get(euConsentMessage)) set(euConsentRequired, false);

  const id = props.newsletterId || '';
  api.getMarketingNewsletterCopy(id)
    .then((response) => {
      const res = response.data.result;
      set(title, res?.title || null);
      set(text, res?.text || null);
      set(confirmationText, res?.confirmationText || null);
    })
    .catch((err) => {
      set(newsletterTextError, 'Whoops! Something went wrong. Try again later.');
    });
};

getNewsletter();

const subscribeUserToNewsletters = () => {
  set(error, false);

  if (email.isValid(get(userName))) {
    if (!get(requireCheck)) {
      set(euConsentGiven, true);
    }

    const msg = `${get(euConsentMessage)} 'Privacy Policy'`;

    api.subscribeUserToNewsletters(get(userName), siteConfig('global.dripTag'), get(euConsentGiven), msg)
      .then((response) => {
        set(signupSuccess, true);
        set(inputState, null);

        amplitudeEvent('infinite', 'infiniteNewsletterSignup', {
          path: window?.location?.pathname,
          source: 'Header',
          contentVertical: page.getVertical(),
          dripTag: siteConfig('global.dripTag'),
          euConsentGiven: get(euConsentGiven),
        });

        set(error, null);

        setTimeout(() => {
          set(signupSuccess, false);
        }, 3000);
      })
      .catch((err) => {
        set(signupSuccess, false);
        set(error, 'Whoops! Something went wrong, please try again.');
      });
  } else {
    set(inputState, 'invalid');
    set(error, 'Invalid email format.');
  }
};
</script>

<style lang="scss" scoped>
.mobile-newsletter {
  padding: $martech-spacer-3;

  .input-wrapper {
    display: flex;
    flex-direction: column;

    :deep(.tcg-input-text) {
      margin-bottom: $martech-spacer-2;
      width: 100%;
    }

    :deep(.martech-button) {
      width: 100%;
    }

    .error-txt {
      color: $martech-system-danger;
      font-size: $martech-type-12;
      margin-bottom: $martech-spacer-2;
    }
  }

  &--copy {
    &.dark {

      h2,
      p {
        color: $martech-white;
      }
    }

    &.light {

      h2,
      p {
        color: $martech-black;
      }
    }
  }

  .consent {
    display: flex;
    margin-top: $martech-spacer-3;

    :deep(.checkbox) {
      align-items: flex-start;
    }

    p,
    a {
      font-size: $martech-type-12;
    }

    &.dark {
      p {
        color: $martech-white;
      }

      :deep(.martech-base-link) {
        color: $martech-blue-20;
      }
    }

    &.light {
      :deep(.martech-base-link) {
        color: $martech-link;
      }
    }
  }

  &--columns {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  &--column {
    width: 100%;
  }

  .subscribe-button {
    margin-left: $martech-spacer-3;
    width: 26%;

    :deep(.martech-button) {
      width: 100%;
    }
  }

  &--copy {
    padding-bottom: $martech-spacer-3;

    h2 {
      font-family: $martech-display-semi-condensed;
      font-size: $martech-type-24;
      line-height: $martech-type-30;
      text-transform: uppercase;
      padding-bottom: $martech-spacer-2;
      letter-spacing: 0.5px;
    }

    p {
      font-size: $martech-type-14;
      line-height: $martech-type-18;
      margin-bottom: 0;
    }
  }
}

// Confirmation Toast Animation CSS
div>.subscribe-confirmation-toast {
  box-shadow: none;

  &.newsletter-dark {
    background-color: transparent;
  }

  &.is-hidden {
    display: none;
  }

  .subscribe-checkmark {
    display: block;
    margin: auto;
  }

  &.is-active {
    height: 100%;
    animation: toast-close 0.25s forwards;
    animation-iteration-count: 1;
    animation-delay: 4s;
  }
}
</style>
