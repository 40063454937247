export default {
  data() {
    return {
      menus: [
        {
          game: 'magic',
          title: 'Magic',
          home: '/magic-the-gathering',
          includeMobile: true,
          mainMenuLink: true,
        },
        {
          game: 'edge',
          title: 'Edge',
          home: '/edge',
          includeMobile: true,
          mainMenuLink: true,
        },
        {
          game: '',
          title: 'TCGplayer Infinite ',
          home: process.env.VUE_APP_TCGPLAYER_INFINITE_URL,
          externalLink: true,
          includeMobile: true,
          mainMenuLink: true,
        },
        {
          game: '',
          title: 'Shop TCGplayer',
          home: process.env.VUE_APP_TCGPLAYER_ROOT_URL,
          externalLink: true,
          includeMobile: true,
          mainMenuLink: true,
        },
        {
          game: 'more',
          title: 'More',
          open: false,
          includeMobile: false,
          links: [
            {
              linkText: 'Flesh and Blood',
              link: `${process.env.VUE_APP_TCGPLAYER_INFINITE_URL}/flesh-and-blood`,
              externalLink: true,
            },
            {
              linkText: 'Archive',
              link: '/archive',
              externalLink: false,
            },
            {
              linkText: 'What is Team CFB-Ultimate Guard?',
              link: '/article/Introducing%20Team%20CFBUltimateGuard%20for%202023/f62f98c5-4dd4-4149-acc9-3da03ac29062',
              externalLink: false,
            },
          ],
        },
      ],
    };
  },
};
