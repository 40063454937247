<template>
  <on-enter-navigate-card :data="articleData" external-link>
    <div v-if="render" class="martech-card martech-light shadow martech-card--is-link" :data-testid="componentID()">
      <exact-match-tag v-show="articleData.isExactMatch"/>
      <div class="card-hero">
        <image-card class="result" :loaded="render" :card-data="articleData.cardData" allow-magnify :card-dimensions="imageCardDimensions" @magnify="magnify"/>
      </div>
      <div class="martech-card__content">
        <div v-if="showVerticalLink && vertical.toLowerCase() !== 'announcements'" class="card__content--vertical">
          <p class="martech-labels martech-blue" :data-testid="componentID('vertical')">
            {{ getArticleTypeTag }}
          </p>
        </div>
        <div class="martech-card__content--body">
          <h3 class="martech-body martech-barlow martech-hyphens martech-two-lines" :data-testid="componentID('title')">
            {{ displayName }}
          </h3>
          <div class="set-group">
            <p class="martech-text-sm martech-two-lines" :data-testid="componentID('set-name')">
              {{ setName }}
            </p>
            <p class="martech-text-sm martech-text-capitalize martech-one-line rarity" :data-testid="componentID('rarity')">
              {{ rarity }}
            </p>
          </div>
          <p class="martech-text-sm martech-text-capitalize martech-one-line" :data-testid="componentID('card-type')">
            {{ cardType }}
          </p>
        </div>
        <div class="group">
          <market-price-template
            align-text="left"
            class="card-card--price"
            :price="marketPrice"/>
          <div class="buy-button">
            <base-button
              v-if="canonicalURL"
              :link="{linkUrl: cardProductUrlUtm, linkText: 'Buy on TCGplayer', urlTarget: '_blank'}"
              btn-style="martech-black-outline"
              btn-size="martech-small"
              @click="buyEvent"/>
          </div>
        </div>
      </div>
      <desktop-magnify-image
        v-show="openModal"
        :card-data="articleData.cardData"
        :card-id="articleData.tcgPlayerID"
        :analytics-data="analyticsData"
        :card-product-url="cardProductUrlUtm"
        @close="closeModal"
        @magnifyBuy="onClick(articleData, 'magnify')"/>
      <mobile-product-details-sheet
        :card-data="articleData.cardData"
        :loaded="render"
        :analytics-data="analyticsData"
        :card-id="articleData.tcgPlayerID"
        :card-product-url="cardProductUrlUtm"
        :show="openModal"
        @close="closeModal"/>
    </div>
  </on-enter-navigate-card>
</template>

<script>
import {
  ImageCard,
  BaseButton,
  MarketPriceTemplate,
  ImageHelper as image,
  VerticalHelpers as verts
} from '@tcgplayer/martech-components';
import DesktopMagnifyImage from '@/components/product-details/DesktopMagnifyImage.vue';
import MobileProductDetailsSheet from '@/components/product-details/MobileProductDetailsSheet.vue';
import ExactMatchTag from '@/components/card-elements/ExactMatchTag.vue';
import truncate from '@/mixins/filters/truncate';
import product from '@/components/card-elements/Cards/mixins/product';
import imageAlter from '@/mixins/image/alter';
import OnEnterNavigateCard from '@/components/card-elements/OnEnterNavigateCard.vue';
import url from '@/lib/url';
import deviceType from '@/mixins/deviceType';
import amplitudeEvent from '@tcgplayer/amplitude';

export default {
  name: 'card-grid-product',
  components: {
    MarketPriceTemplate,
    DesktopMagnifyImage,
    MobileProductDetailsSheet,
    ExactMatchTag,
    OnEnterNavigateCard,
    ImageCard,
    BaseButton,
  },
  mixins: [ truncate, product, imageAlter, deviceType ],
  props: {
    articleData: {
      type: Object,
      required: true,
      default: null,
    },
    analyticsData: {
      type: Object,
      required: false,
      // eslint-disable-next-line
      default: () => { return { analyticsCampaign: 'search', analyticsSource: process.env.VUE_APP_NAME_ANALYTICS, analyticsMedium: 'product-card'}; }
    },
    showVerticalLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: false,
      default: (article) => {},
    },
  },
  data() {
    return {
      articleDate: '',
      canonicalURL: '',
      cardID: null,
      cardType: '',
      contentType: '',
      displayName: '',
      imageAltName: '',
      marketPrice: null,
      render: false,
      vertical: '',
      magnifyButton: true,
      openModal: false,
      setName: '',
      fallbackImage: false,
    };
  },
  computed: {
    getArticleTypeTag() {
      if (this.vertical.toLowerCase() === 'product') {
        // Renames Product vertical to TCGplayer Product Spotlight on product specific cards
        return 'TCGplayer Product Spotlight';
      }
      return verts.displayName(this.vertical);
    },
    cardProductUrlUtm() {
      return url.urlUtm(this.analyticsData, this.canonicalURL);
    },
    image() {
      return image.getCardImage(this.articleData.tcgPlayerID) || this.articleData.image;
    },
    imageCardDimensions() {
      return this.deviceType === 'mobile' ? { width: '120px', height: '170px' } : { width: '134px', height: '195px' };
    },
    cardFallbackImage() {
      return image.getFallbackImage(null, 'card-fallback-image');
    },
  },
  methods: {
    errorImageFallback(event) {
      if (!this.fallbackImage) {
        this.fallbackImage = true;
        event.target.src = this.articleData.image;
        return;
      }

      event.target.src = this.cardFallbackImage;
    },
    magnify() {
      this.openModal = true;
    },
    closeModal() {
      this.openModal = false;
    },
    buyEvent() {
      amplitudeEvent('martech', 'buyButton', {
        component: this.$options.name,
        title: this.title,
        product_type: this.articleData.contentType,
        product_line: this.articleData.vertical,
        itm_source: this.analyticsData.analyticsSource,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.martech-card {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: $martech-spacer-2;

  .martech-card-hero {
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__content {
    padding: 0;
    width: 70%;
    position: relative;
    margin-left: $martech-spacer-3;

    &--vertical {
      padding-bottom: $martech-spacer-1;
    }

    &--body {
      position: relative;

      @include breakpoint(768) {
        min-height: 95px;
      }

      @include breakpoint(1024) {
        min-height: 112px;
      }

      h3 {
        margin-bottom: $martech-spacer-1;
      }

      p {
        margin: 0;
      }

      .set-group {
        p {
          color: $martech-text-subdued;
          font-weight: $martech-weight-semibold;
        }

        .rarity {
          font-weight: $martech-weight-normal;
        }
      }
    }

    .buy-button {
      display: inline-block;
      width: 100%;
      padding-top: $martech-spacer-2;

      :deep(a) {
        width: 100%;
      }
    }

    .market-price {
      padding: $martech-spacer-2 0;
    }
  }
}

:deep(.martech-image) {
    .martech-image__button {
      .martech-magnify-button {
        top: -130px;
    }
  }
}

:deep(.exact-match) {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 20;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
