<template>
  <div v-if="bestMatch" class="pagination">
    <button :disable="!currentPosition" @click="handlePrevious">
      <img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/arrow-left.svg" alt="previous month">
    </button>
    <p>
      {{ bestMatch }}
    </p>
    <button :disable="currentPosition >= months?.length" @click="handleNext">
      <img src="https://tcgplayer-cdn.tcgplayer.com/infinite/images/arrow-right.svg" alt="next month">
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useThrottleFn } from '@vueuse/core';

let timeline;
let months;
const bestMatch = ref('');
const currentPosition = ref(0);

const handleScroll = useThrottleFn(() => {
  if (!months.length) return;

  for (let i = 0; i < months.length; i++) {
    const rect = months[i].getBoundingClientRect();
    if (rect.top > 50) {
      bestMatch.value = months[i].getElementsByTagName('p')[0].innerText;
      currentPosition.value = i;
      break;
    }
  }
}, 100);

onMounted(() => {
  [ timeline ] = window.document.getElementsByClassName('range-timeline');
  months = timeline.getElementsByClassName('wrap');
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

const handlePrevious = () => {
  window.scrollBy(0, -217);
};

const handleNext = () => {
  window.scrollBy(0, 217);
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
}

button {
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
  }
}
</style>
