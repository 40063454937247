<template>
  <delay-load @visible="onVisible">
    <div class="latest-decks" :class="{'block' : blockGrid}" :data-testid="componentID()">
      <div class="header">
        <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
          {{ title }}
        </h2>
        <router-link
          v-if="isDesktop && seeMore"
          :to="seeMore"
          :aria-label="`See All ${title}`"
          class="martech-see-all-link">
          See All
        </router-link>
      </div>
      <div class="latest-decks--grid">
        <div v-if="loading" class="placeholder-component">
          <full-art-placeholder v-for="p in itemCount" :key="p" class="item"/>
        </div>
        <card-grid
          v-if="!loading && orderedDecks.length"
          :articles="orderedDecks"
          :on-click="onClick"
          :column-count="itemCount"
          :grid-rows-count="gridRowsCount"
          :show-vertical-link="showVerticalLink"
          :include-promo="false"
          :row-count="gridRows"
          @click="handleClick"/>
        <router-link
          v-if="isMobile && seeMore"
          :to="seeMore"
          :aria-label="`See All ${title}`"
          class="martech-see-all-link see-all-mobile">
          See All
        </router-link>
      </div>
    </div>
  </delay-load>
</template>

<script setup>
import { computed, ref } from 'vue';

import Api from '@/api/api';

import DelayLoad from '@/components/DelayLoad.vue';
import FullArtPlaceholder from '@/components/placeholder-components/FullArtPlaceholder.vue';
import CardGrid from '@/components/article-list/CardGrid.vue';
import amplitudeEvent from '@tcgplayer/amplitude';

import { useDeviceType, useRandomizeArray } from '@tcgplayer/martech-components';

const props = defineProps({
  verticalName: {
    type: String,
    required: false,
    default: '',
  },
  itemCount: {
    type: Number,
    required: false,
    default: 4,
  },
  gridRows: {
    type: Number,
    required: false,
    default: 2,
  },
  gridRowsCount: {
    type: Number,
    default: null,
    required: false,
    note: 'defines a different count for the grid template rows styling other than column count if needed',
  },
  seeMore: {
    type: [ String, Object ],
    required: false,
    default: '',
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  showVerticalLink: {
    type: Boolean,
    required: false,
    default: false,
  },
  featured: {
    type: Boolean,
    required: true,
    default: null,
  },
  popular: {
    type: Boolean,
    required: false,
    default: false,
  },
  bucketSize: {
    type: String,
    required: false,
    default: 'month',
  },
  placement: {
    type: String,
    required: false,
    default: 'Homepage',
  },
  onClick: {
    type: Function,
    required: false,
    default: (article) => {},
  },
  blockGrid: {
    type: Boolean,
    required: false,
    default: false,
  },
  randomOrder: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { isMobile, isDesktop } = useDeviceType();

const decks = ref([]);
const loading = ref(true);

const handleClick = (deck) => {
  const data = {
    title: deck?.title,
    productLine: deck?.vertical,
    format: deck?.deckData?.format || '',
    url: deck?.canonicalURL,
    source: 'Decks Grid',
    player: deck?.deckData?.ownerName || '',
  };
  amplitudeEvent('infinite', 'infiniteDeckClicked', data);
};

const orderedDecks = computed(() => {
  if (!props.randomOrder) {
    return decks.value;
  }

  const { randomizedArray } = useRandomizeArray(decks);
  return randomizedArray.value;
});

const popularContent = async () => {
  const res = await await Api.getTrendingDecks({
    bucketSize: props.bucketSize, game: props.verticalName, rows: props.itemCount,
  });

  decks.value = res?.data?.result || [];

  for (let i = 0; i < decks.value.length; i++) {
    decks.value[i].type = 'deck';
    decks.value[i].title = decks.value[i].deck.name;
  }

  loading.value = false;
};

const latestFeaturedContent = async () => {
  let res;
  if (props.featured === false) {
    res = await Api.getDeckFeed({
      game: props.verticalName,
      offset: props.offset,
      rows: props.itemCount,
    });
  } else {
    res = await Api.getFeaturedDecks(props.verticalName, props.offset, props.itemCount);
  }

  decks.value = res?.data?.result || [];
  loading.value = false;
};

const latestContent = async () => {
  const res = await Api.getDeckFeed({
    rows: props.itemCount, offset: props.offset, game: props.verticalName,
  });
  decks.value = res?.data?.result || [];

  loading.value = false;
};

const onVisible = () => {
  if (props.popular) {
    popularContent();
  } else if (props.featured) {
    latestFeaturedContent();
  } else {
    latestContent();
  }
};

</script>

<style lang="scss" scoped>
.latest-decks {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $martech-spacer-2;
    min-height: 35px;
  }

  .see-all-mobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: $martech-spacer-3;
  }

  .placeholder-component {
    display: flex;
    flex-wrap: wrap;
    grid-gap: $martech-spacer-3;

    @include breakpoint(1024) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(1200) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  :deep(.grid) {
    .grid-item {
      @include breakpoint(1200) {
        margin-bottom: 0;
      }
    }
  }
}

.block {
  .placeholder-component {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(1024) {
      height: 601px;
    }

    .item {
      width: 100%;
      padding-bottom: $martech-spacer-3;

      &:last-of-type {
        padding-bottom: 0;
      }

      @include breakpoint(1024) {
        flex: 0 50%;
        height: 301px;

        &:nth-child(1),
        &:nth-child(3) {
          padding-right: $martech-spacer-3;
        }

        &:nth-child(1),
        &:nth-child(2) {
          padding-bottom: $martech-spacer-3;
        }
      }

      @include breakpoint(1200) {
        &:nth-child(3) {
          padding-bottom: 0px;
        }
      }
    }
  }
}
</style>
