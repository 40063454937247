<template>
  <div
    v-if="showOnMouseOut && showOnScroll"
    class="subscribe-drawer--container"
    :aria-hidden="!isExitIntentVisible"
    :data-testid="componentID()">
    <div class="subscribe-drawer shadow" :class="newsletterDrawerVisibility" @click="escSubscribeDrawer">
      <div class="container">
        <div class="subscribe-drawer--toggle-closed">
          <button
            v-if="isExitIntentVisible"
            class="close-button"
            type="button"
            data-toggle="collapse"
            data-target="#account-menu"
            aria-label="Close Newsletter Popup"
            @click="toggleClosed">
            <icon-base
              icon-name="close popup"
              icon-color="currentColor"
              width="15"
              height="15">
              <close-icon/>
            </icon-base>
          </button>
        </div>
        <div class="subscribe-drawer--columns">
          <div class="subscribe-drawer--column">
            <div class="subscribe-drawer--copy">
              <h2>{{ title }}</h2>
              <p>{{ text }}</p>
            </div>
          </div>
          <div class="subscribe-drawer--column">
            <div
              class="input-wrapper">
              <default-input
                v-if="isExitIntentVisible"
                id="emailExit"
                v-model="userName"
                :on-submit="subscribeUserToNewsletters"
                :button="{ label: 'Sign Up', position: 'right', size: 'martech-large', style: 'martech-primary' }"
                label="Email"
                hide-label
                :error="error"
                placeholder="Email"
                :error-message="error"
                theme-color="dark"/>
            </div>
            <div class="consent">
              <default-checkbox
                v-if="isExitIntentVisible && requireCheck && euConsentMessage"
                @on-change="euConsentGiven = !euConsentGiven"/>
              <p class="martech-sm-detail">
                {{ euConsentMessage }} <span><base-link
                  v-if="isExitIntentVisible"
                  :link-url="privacyPolicyUrl"
                  new-window
                  url-target="_blank"
                  class="martech-sm-detail">
                  Privacy Policy
                </base-link></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <subscribe-confirmation-toast
      :confirmation-text="confirmationText"
      theme="dark"
      :class="signupSuccess ? 'is-active' : 'is-hidden'"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import delve from 'dlv';
import amplitudeEvent from '@tcgplayer/amplitude';
import {
  DefaultCheckbox,
  DefaultInput,
  BaseLink,
  EmailHelper as email,
  PageHelpers as page
} from '@tcgplayer/martech-components';
import api from '@/api/api';
import IconBase from '@/components/iconography/IconBase.vue';
import CloseIcon from '@/components/iconography/CloseIcon.vue';
import SubscribeConfirmationToast from '@/components/newsletters/SubscribeConfirmationToast.vue';
import cookies from '@/mixins/cookies';

const NEWSLETTER_STORAGE = 'NewsletterPopupSettings';

export default {
  name: 'exit-intent-newsletter-popup',
  components: {
    IconBase,
    CloseIcon,
    SubscribeConfirmationToast,
    DefaultCheckbox,
    DefaultInput,
    BaseLink,
  },
  mixins: [
    cookies,
  ],
  props: {
    newsletterId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      userName: null,
      signupSuccess: null,
      error: null,
      newsletterTextError: null,
      text: null,
      title: null,
      confirmationText: null,
      euConsentRequired: true,
      euConsentGiven: false,
      euConsentMessage: 'I consent to receive information about special offers by email from TCGplayer.',
      privacyPolicyUrl: 'https://tcgplayer.com/privacypolicy',
      requireCheck: false,
    };
  },
  computed: {
    ...mapState('sidebar', {
      isExitIntentVisible: 'isExitIntentVisible',
    }),
    newsletterDrawerVisibility() {
      return {
        'is-news-active': this.isExitIntentVisible,
      };
    },
  },
  mounted() {
    document.addEventListener('mouseout', this.showOnMouseOut);
    document.addEventListener('keyup', this.escSubscribeDrawer);
    window.addEventListener('scroll', this.showOnScroll);
  },
  unmounted() {
    document.removeEventListener('mouseout', this.showOnMouseOut);
    document.removeEventListener('keyup', this.escSubscribeDrawer);
    window.addEventListener('scroll', this.showOnScroll);
  },

  async created() {
    if (!this.euConsentMessage) this.euConsentRequired = false;

    const id = this.newsletterId || '';
    api.getMarketingNewsletterCopy(id)
      .then((response) => {
        const res = response.data.result;
        this.title = delve(res, 'title', null);
        this.text = delve(res, 'text', null);
        this.confirmationText = delve(res, 'confirmationText', '');
      })
      .catch((newsletterTextError) => {
        this.newsletterTextError = 'Whoops! Something went wrong. Try again later.';
      });
  },
  methods: {
    ...mapActions({
      toggleExitIntent: 'sidebar/toggleExitIntent',
    }),
    subscribeUserToNewsletters() {
      this.error = false;

      if (email.isValid(this.userName)) {
        if (!this.requireCheck) {
          this.euConsentGiven = true;
        }

        const msg = `${this.euConsentMessage} 'Privacy Policy'`;

        api.subscribeUserToNewsletters(this.userName, this.siteConfig('global.dripTag'), this.euConsentGiven, msg)
          .then((response) => {
            this.signupSuccess = true;

            amplitudeEvent('infinite', 'infiniteNewsletterSignup', {
              path: delve(window, 'location.pathname'),
              source: 'Exit Intent',
              contentVertical: page.getVertical(),
              dripTag: this.dripTag,
              euConsentGiven: this.euConsentGiven,
            });

            this.setNewsletterPopupCookieSubscribed();
            this.error = null;
            this.toggleExitIntent(false);

            setTimeout(() => {
              this.signupSuccess = false;
            }, 3000);
          })
          .catch((error) => {
            this.signupSuccess = false;
            this.error = 'Whoops! Something went wrong, please try again.';
          });
      } else {
        this.error = 'Invalid email format.';
      }
    },
    showOnMouseOut(event) {
      const popupTrigger = document.getElementById('app');
      if (event.clientY < 50 && event.relatedTarget == null && popupTrigger) {
        if (!this.getNewsletterStatus()) {
          setTimeout(() => this.toggleExitIntent(true), 500);
        }
      }
    },
    showOnScroll() {
      // Only show if no status set
      if (this.getNewsletterStatus()) return;

      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;

      if (scrolled >= 25) {
        this.toggleExitIntent(true);
      } else if (scrolled === 0) {
        this.toggleExitIntent(false);
      }
    },
    toggleClosed() {
      this.toggleExitIntent(false);
      this.setNewsletterPopupCookie();
    },
    setNewsletterPopupCookieSubscribed() {
      this.storeNewsletterStatus(90);
    },
    setNewsletterPopupCookie() {
      this.storeNewsletterStatus(5);
    },
    getNewsletterStatus() {
      try {
        const now = Date.now();
        const local = localStorage.getItem(NEWSLETTER_STORAGE) || now;
        return !!(now < local || this.getCookie(NEWSLETTER_STORAGE)); // Cookie check kept so people don't get annoyed during transition
      } catch (err) {
        // Wrap it in case we're in a private browsing session
        return false;
      }
    },
    storeNewsletterStatus(expireDays) {
      try {
        localStorage.setItem(NEWSLETTER_STORAGE, Date.now() + 86400000 * expireDays); // 86400000 ms = 1 day
      } catch (err) {
        // Wrap it in case we're in a private browsing session
      }
    },
    escSubscribeDrawer(event) {
      if (event.keyCode === 27) {
        this.toggleClosed();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe-drawer {
  position: fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 300ms ease-in-out;
  z-index: 3147483640;
  background-color: rgba(0, 0, 0, 0.90);
  bottom: -100px;

  &.is-news-active {
    bottom: 0;
    height: 300px;
    padding: $martech-spacer-3 0;

    @include breakpoint(1024) {
      padding: $martech-spacer-3;
      height: 192px;
    }
  }

  h2,
  p {
    color: $martech-white;
  }

  .consent {
    display: flex;
    margin-top: $martech-spacer-3;

    :deep(.checkbox) {
      align-items: flex-start;
    }

    p,
    a {
      font-size: $martech-type-14;
    }

    :deep(.martech-base-link) {
      color: $martech-blue-10;
      &:focus-visible {
        outline: 2px dashed $martech-blue-10;
      }
    }
  }

  .close-button {
    color: $martech-white;
    &:focus-visible {
      outline: 2px dashed $martech-blue-10;
    }
  }

  .subscribe-button {
    margin-left: $martech-spacer-3;
    width: 25%;
  }

  &.is-hidden {
    display: none;
  }

  &--columns {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include breakpoint(1024) {
      justify-content: space-between;
      flex-direction: row;
      height: 80%;
      padding-bottom: $martech-spacer-7;
    }
  }

  &--column {
    width: 100%;
    margin-top: $martech-spacer-1;

    .input-wrapper {
      :deep(button) {
        &:focus-visible {
          outline: 2px dashed $martech-blue-10;
        }
      }
    }
  }

  &--toggle-closed {
    display: flex;
    justify-content: flex-end;
    padding-bottom: $martech-spacer-3;

    @include breakpoint(1024) {
      padding-bottom: 0;
    }

    button {
      background: transparent;
      border: 0;
      padding: $martech-spacer-2;

      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 1);
        border-radius: $martech-radius-default;
        outline: 0;
      }
    }
  }

  &--copy {
    padding-bottom: $martech-spacer-3;

    @include breakpoint(1024) {
      padding-bottom: 0;
    }

    h2 {
      font-family: $martech-display-semi-condensed;
      font-size: $martech-type-26;
      line-height: $martech-type-32;
      text-transform: uppercase;
      padding-bottom: $martech-spacer-2;
      letter-spacing: 0.5px;
    }

    p {
      font-size: $martech-type-14;
      line-height: $martech-type-16;
      margin-bottom: 0;

      @include breakpoint(1024) {
        width: 75%;
      }
    }
  }
}

// Confirmation Toast Animation CSS
div > .subscribe-confirmation-toast {
  position: fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  z-index: 3147483640;

  &.is-hidden {
    bottom: -100px;
  }

  &.is-active {
    height: 200px;
    bottom: 0;
    animation: toast-close 0.25s forwards;
    animation-iteration-count: 1;
    animation-delay: 4s;

    @include breakpoint(1200) {
      height: 98px;
    }
  }
}
</style>
