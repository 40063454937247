<template>
  <div v-show="isMdOrGreater" :aria-hidden="!isNewsVisible && !signupSuccess" class="subscribe-drawer--container" :data-testid="componentID()">
    <div
      class="subscribe-drawer shadow"
      :class="[newsletterDrawerVisibility, {'dark-mode' : siteConfig('global.darkMode')}]"
      @click="escSubscribeDrawer">
      <div class="container">
        <div class="subscribe-drawer--toggle-closed">
          <button
            v-if="isNewsVisible"
            class="close-button"
            type="button"
            data-toggle="collapse"
            data-target="#account-menu"
            aria-label="Close Newsletter Popup"
            @click="toggleNewsDrawer(false)">
            <icon-base icon-name="close" icon-color="currentColor" width="15" height="15">
              <close-icon/>
            </icon-base>
          </button>
        </div>
        <div class="subscribe-drawer--columns">
          <div class="subscribe-drawer--column">
            <div class="subscribe-drawer--copy">
              <h2>{{ title }}</h2>
              <p>{{ text }}</p>
            </div>
          </div>
          <div class="subscribe-drawer--column">
            <div
              class="input-wrapper">
              <default-input
                v-if="isNewsVisible"
                id="emailHeader"
                v-model="userName"
                :on-submit="subscribeUserToNewsletters"
                :button="{ label: 'Sign Up', position: 'right', size: 'martech-large', style: 'martech-primary' }"
                label="Email"
                hide-label
                :error="error"
                placeholder="Email"
                :error-message="error"
                :theme-color="siteConfig('global.darkMode') ? 'dark' : 'light'"/>
            </div>
            <div v-if="isNewsVisible" class="consent">
              <default-checkbox v-if="requireCheck && euConsentMessage" @on-change="euConsentGiven = !euConsentGiven"/>
              <p class="martech-sm-detail">
                {{ euConsentMessage }} <span><base-link :link-url="privacyPolicyUrl" new-window url-target="_blank" class="martech-sm-detail">
                  Privacy Policy
                </base-link></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <subscribe-confirmation-toast
      v-show="signupSuccess"
      :confirmation-text="confirmationText"
      :theme="siteConfig('global.darkMode') ? 'dark' : 'light'"
      :class="signupSuccess ? 'is-active' : 'is-hidden'"/>
    <div
      class="subscribe-overlay"
      :class="newsletterDrawerVisibility"
      @click="toggleNewsDrawer(false)"/>
  </div>
</template>

<script>
import api from '@/api/api';
import CloseIcon from '@/components/iconography/CloseIcon.vue';
import IconBase from '@/components/iconography/IconBase.vue';
import SubscribeConfirmationToast from '@/components/newsletters/SubscribeConfirmationToast.vue';
import amplitudeEvent from '@tcgplayer/amplitude';
import {
  BaseLink, DefaultCheckbox, DefaultInput, DeviceType, EmailHelper as email, PageHelpers as page
} from '@tcgplayer/martech-components';
import delve from 'dlv';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'header-subscribe-drawer',
  components: {
    IconBase,
    CloseIcon,
    SubscribeConfirmationToast,
    DefaultCheckbox,
    BaseLink,
    DefaultInput,
  },
  mixins: [ DeviceType ],
  props: {
    newsletterId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      userName: null,
      signupSuccess: null,
      error: null,
      newsletterTextError: null,
      text: null,
      title: null,
      confirmationText: null,
      euConsentRequired: true,
      euConsentGiven: false,
      euConsentMessage: 'I consent to receive information about special offers by email from TCGplayer.',
      privacyPolicyUrl: 'https://tcgplayer.com/privacypolicy',
      requireCheck: false,
    };
  },
  computed: {
    ...mapState('sidebar', {
      isNewsVisible: 'isNewsVisible',
    }),
    isMdOrGreater() {
      return [ 'md', 'lg', 'xl' ].includes(this.deviceTypeSpecific);
    },
    newsletterDrawerVisibility() {
      return {
        'is-news-active': this.isNewsVisible,
      };
    },
  },
  mounted() {
    document.addEventListener('keyup', this.escSubscribeDrawer);
  },
  unmounted() {
    document.removeEventListener('keyup', this.escSubscribeDrawer);
  },
  async created() {
    if (!this.euConsentMessage) this.euConsentRequired = false;

    const id = this.newsletterId || '';
    api.getMarketingNewsletterCopy(id)
      .then((response) => {
        const res = response.data.result;
        this.title = delve(res, 'title', null);
        this.text = delve(res, 'text', null);
        this.confirmationText = delve(res, 'confirmationText', '');
      })
      .catch((newsletterTextError) => {
        this.newsletterTextError = 'Whoops! Something went wrong. Try again later.';
      });
  },
  methods: {
    ...mapActions({
      toggleNewsDrawer: 'sidebar/toggleNewsDrawer',
    }),
    subscribeUserToNewsletters() {
      this.error = false;

      if (email.isValid(this.userName)) {
        if (!this.requireCheck) {
          this.euConsentGiven = true;
        }

        const msg = `${this.euConsentMessage} 'Privacy Policy'`;

        api.subscribeUserToNewsletters(this.userName, this.siteConfig('global.dripTag'), this.euConsentGiven, msg)
          .then((response) => {
            this.signupSuccess = true;

            amplitudeEvent('infinite', 'infiniteNewsletterSignup', {
              path: delve(window, 'location.pathname'),
              source: 'Header',
              contentVertical: page.getVertical(),
              dripTag: this.dripTag,
              euConsentGiven: this.euConsentGiven,
            });

            this.error = null;
            this.toggleNewsDrawer(false);

            setTimeout(() => {
              this.signupSuccess = false;
            }, 3000);
          })
          .catch((error) => {
            this.signupSuccess = false;
            this.error = 'Whoops! Something went wrong, please try again.';
          });
      } else {
        this.error = 'Invalid email format.';
      }
    },
    escSubscribeDrawer(event) {
      if (event.keyCode === 27) {
        this.toggleNewsDrawer(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe-drawer--container {
  position: absolute;
  top: 0;
  width: 100%;
}

.subscribe-drawer {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  z-index: 3147483640;
  top: -50px;
  background-color: $martech-white;
  display: flex;

  &.dark-mode {
    background-color: $martech-gray-150;

    .close-button {
      color: $martech-white;
    }

    h2,
    p {
      color: $martech-white;
    }

    :deep(.martech-base-link) {
      color: $martech-blue-10;
    }
  }

  &.is-news-active {
    top: 0;
    height: 257px;
    padding: $martech-spacer-3 0;
  }

  h2,
  p {
    color: $martech-black;
  }

  .consent {
    display: flex;
    margin-top: $martech-spacer-3;

    :deep(.checkbox) {
      align-items: flex-start;
    }

    p,
    a {
      font-size: $martech-type-14;
    }

    a {
      color: $martech-link;
    }
  }

  .close-button {
    color: $martech-black;
  }

  &.is-hidden {
    display: none;
  }

  &--columns {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include breakpoint(1024) {
      justify-content: space-between;
      flex-direction: row;
      height: 80%;
      padding-bottom: $martech-spacer-7;
    }
  }

  &--column {
    width: 100%;
  }

  .subscribe-button {
    margin-left: $martech-spacer-3;
    width: 26%;
    :deep(.martech-button) {
      width: 100%;
    }
  }

  &--toggle-closed {
    display: flex;
    justify-content: flex-end;
    padding-bottom: $martech-spacer-3;

    @include breakpoint(1024) {
      padding-bottom: 0;
      padding-top: $martech-spacer-3;
    }

    button {
      background: transparent;
      border: 0;
    }
  }

  &--copy {
    padding-bottom: $martech-spacer-3;

    @include breakpoint(1024) {
      padding-bottom: 0;
    }

    h2 {
      font-family: $martech-display-semi-condensed;
      font-size: $martech-type-26;
      line-height: $martech-type-30;
      text-transform: uppercase;
      padding-bottom: $martech-spacer-2;
      letter-spacing: 0.5px;
    }

    p {
      font-size: $martech-type-14;
      line-height: $martech-type-16;
      margin-bottom: 0;

      @include breakpoint(1024) {
        width: 75%;
      }
    }
  }
}

// Confirmation Toast Animation CSS
div > .subscribe-confirmation-toast {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  z-index: 3147483640;

  &.is-hidden {
    top: -100px;
  }

  &.is-active {
    top: 0;
    height: 136px;
    animation: toast-close 0.25s forwards;
    animation-iteration-count: 1;
    animation-delay: 4s;

    @include breakpoint(1200) {
      height: 98px;
    }
  }
}

//Used to close subscribe drawer when clicking outside
.subscribe-overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  z-index: 100;
  display: none;

  &.is-news-active {
    display: block;
  }
}
</style>
