<template>
  <on-enter-navigate-card :data="data">
    <router-link :to="data.canonicalURL || '#'" tabindex="-1" @click="$emit('click', data)">
      <div ref="root" class="slim-article-card card--is-linked shadow" :data-testid="componentID()">
        <div class="img-wrap">
          <img
            v-if="hasRoot"
            :src="imageURL(data.imageURL || fallbackImage, { maxDimensions: 'autoxauto', quality: 75, })"
            alt=""
            class="article-image"
            @error="errorImageFallback($event)">
        </div>
        <div class="content">
          <p v-show="showVertical" class="martech-labels martech-blue">
            {{ verticalDisplay }}
          </p>
          <h4 class="martech-body martech-barlow martech-two-lines">
            {{ data.title }}
          </h4>
          <div class="group">
            <p v-if="showDate" class="martech-text-sm">
              {{ formattedDate }}
            </p>
            <p class="martech-text-sm time">
              <span v-if="showDate" class="dot">&#xb7;</span>
              <icon-base width="14" height="14" icon-name="read time" color="#1e1e1e">
                <clock-icon/>
              </icon-base>
              {{ data.readTime }} Min Read
            </p>
          </div>
        </div>
      </div>
    </router-link>
  </on-enter-navigate-card>
</template>

<script>
import OnEnterNavigateCard from '@/components/card-elements/OnEnterNavigateCard.vue';
import ClockIcon from '@/components/iconography/ClockIcon.vue';
import IconBase from '@/components/iconography/IconBase.vue';
import imageAlter from '@/mixins/image/alter';
import { DateHelper as dates, ImageHelper as image, VerticalHelpers as verts } from '@tcgplayer/martech-components';

export default {
  name: 'slim-article-card',
  components: {
    IconBase,
    ClockIcon,
    OnEnterNavigateCard,
  },
  mixins: [ imageAlter ],
  props: {
    data: {
      type: Object,
      required: true,
      default: null,
    },
    showVertical: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    fallbackImage() {
      return image.getFallbackImage(this.data.vertical, 'card');
    },
    verticalDisplay() {
      return verts.displayName(this.data.vertical.toLowerCase());
    },
    formattedDate() {
      return dates.format(this.data.date);
    },
  },
  methods: {
    errorImageFallback(event) {
      event.target.src = this.fallbackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.slim-article-card {
  border-radius: $martech-radius-medium;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in-out;
  background-color: $martech-white;

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .img-wrap {
    padding: $martech-spacer-2 0 $martech-spacer-2 $martech-spacer-2;
  }

  .article-image {
    height: 90px;
    width: 90px;
    object-position: top center;
    object-fit: cover;
    border-radius: $martech-radius-medium;
  }

  .content {
    padding: $martech-spacer-2;
    display: flex;
    flex-direction: column;
    width: 75%;
    color: $martech-text-primary;
    min-height: 110px;

    :deep(.martech-labels) {
      margin-bottom: $martech-spacer-1;
    }

    h4 {
      min-height: 44px;
      margin-bottom: $martech-spacer-2;
    }
  }

  .group {
    display: flex;
    align-items: center;

    p {
      line-height: 12px;
    }

    .time {
      display: flex;
      align-items: center;

      .dot {
        padding: $martech-spacer-1;
      }

      svg {
        margin-right: $martech-spacer-1;
      }
    }
  }
}
</style>
