import delve from 'dlv';

const regex = /^(https:\/\/mktg-assets\.tcgplayer\.com)(.*)|(https:\/\/product-images\.tcgplayer\.com)(\/.*\.jpg)$/i;

export default {
  data() {
    return {
      hasRoot: false,
    };
  },
  mounted() {
    this.hasRoot = true;
  },
  methods: {
    /**
     * imageURL takes an image URL and the max width & height that it should be
     * and builds a new URL that will use the image resizing service.
     *
     * Example Usage: <somecomponent :source="imageURL(articleData.imageURL, { maxDimensions: '400x4000', quality: 75, })"/>
     *
     * @param {string} imageURL               A URL to an image
     * @param {Object} options                The ways in which you want to alter the image
     * @param {string} options.maxDimensions  The maximimum width & height written in the form of 400x700
     * @param {number} options.quality        An integer between 0 and 100
     * @param {string} options.watermark      Show a TCGplayer infinite watermark at position Ex. '-30,-30,40'
     *
     * @returns {string} The updated URL with resizing service if valid, otherwise the original imageURL
     */
    imageURL(imageURL, { maxDimensions, quality, watermark }) {
      if (!imageURL) return '';

      if (!maxDimensions && !quality && !watermark) return imageURL;

      const matches = imageURL.match(regex);

      if (!matches) return imageURL;

      let start;
      let end;

      /* eslint-disable prefer-destructuring */
      if (matches[1]) {
        start = matches[1];
        end = matches[2];
      } else if (matches[3]) {
        start = matches[3];
        end = matches[4];
      } else {
        return imageURL;
      }
      /* eslint-enable prefer-destructuring */

      let modifiers = '';

      if (maxDimensions) {
        let [ width, height ] = maxDimensions.split('x');

        const root = delve(this, '$refs.imageRoot', delve(this, '$refs.root'));

        if (root) {
          const w = root.clientWidth || 0;
          const h = root.clientHeight || 0;

          if (width === 'auto' && height === 'auto') {
            if (w < h) {
              height = Math.ceil(h / 100) * 100 + 100; // Round up to nearest 100 to group requests for caching
              width = height;
            } else {
              width = Math.ceil(w / 100) * 100 + 100; // Round up to nearest 100 to group requests for caching
              height = width;
            }
          } else if (width === 'auto') {
            width = Math.ceil(w / 100) * 100 + 100; // Round up to nearest 100 to group requests for caching
          } else if (height === 'auto') {
            height = Math.ceil(h / 100) * 100 + 100; // Round up to nearest 100 to group requests for caching
          }
        }

        // If we are set to auto and root fails we don't want to set a modifier as we don't know what to put
        if (width !== 'auto' && height !== 'auto') {
          modifiers += `/fit-in/${width}x${height}`;
        }
      }

      if (quality) {
        modifiers += `/filters:quality(${quality})`;
      }

      if (watermark) {
        modifiers += `/filters:watermark(tcgplayer-cdn-prd,infinite-opengraph-watermark.png,${watermark})`;
      }

      return `${start}${modifiers}${end}`;
    },
  },
};
