<template>
  <div>
    <Transition>
      <div v-show="visible" class="chart-container">
        <canvas ref="canvas" class="canvas"/>
        <div ref="overlay" class="overlay"/>
      </div>
    </Transition>
    <div v-show="visible" class="price-overlay">
      <div class="pagination-wrap">
        <pagination-button @change="handlePageChange"/>
      </div>
      <p>{{ title }}: <span class="price">{{ priceDisplay }}</span></p>
    </div>
  </div>
</template>

<script setup>
import {
  inject,
  onMounted,
  ref,
  computed
} from 'vue';
import {
  Chart, LineElement, PointElement, LineController, CategoryScale, LinearScale, Tooltip
} from 'chart.js';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import PaginationButton from '@/components/timeline/PaginationButton.vue';

gsap.registerPlugin(ScrollTrigger);
Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Tooltip);

const props = defineProps({
  labels: {
    type: String,
    default: undefined,
  },
  data: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    default: '',
  },
});

const labels = ref([]);
const datasets = ref([]);
const canvas = ref(null);
const overlay = ref(null);
let myChart; // eslint-disable-line
const prices = inject('articleJson')?.[props.data] || [];
const price = ref(prices[0]);
const priceDisplay = computed(() => parseFloat(price.value).toFixed(2));
const visible = ref(false);

if (!props.labels) {
  const buildLabels = [];
  for (let i = 0; i < prices.length; i++) {
    buildLabels.push('');
  }
  labels.value = buildLabels;
} else {
  labels.value = props.labels?.split('|');
}

datasets.value = [ {
  borderColor: '#E2FF30',
  lineTension: 0.2,
  data: prices,
} ];

onMounted(() => {
  myChart = new Chart(canvas.value, {
    type: 'line',
    data: {
      labels: labels.value,
      datasets: datasets.value,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          pointStyle: 'circle',
          backgroundColor: '#E2FF30',
          radius: 0,
        },
        line: {
          borderWidth: 2,
          tension: 0.3,
        },
      },
      scales: {
        x: {
          border: {
            display: false,
          },
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
        y: {
          border: {
            display: false,
          },
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false,
            lineWidth: 0,
          },
          ticks: {
            display: false,
            beginAtZero: true,
            min: 0,
          },
        },
      },
    },
  });

  // The goal is to slowly reveal the chart as they scroll down.  Base it on the article body
  const articleBody = Array.from(document.getElementsByClassName('article-body'));
  const root = articleBody[articleBody.length - 1];

  gsap.to(overlay.value, {
    x: '100%',
    scrollTrigger: {
      trigger: root,
      start: 'top top',
      end: `+=${root.offsetHeight * 0.92}`,
      scrub: 0.01,
      onUpdate: (self) => {
        const offset = 1 / prices.length;
        const position = Math.floor(self.progress / offset);
        if (prices[position]) {
          price.value = prices[position];
        }

        const bounding = root.getBoundingClientRect();
        visible.value = !(bounding.bottom + 100 <= window.innerHeight);
      },
    },
  });
});

const handlePageChange = (newPage) => {
  const el = window.document.getElementsByClassName('animated-card')[newPage - 1];

  if (el) {
    const rect = el.getBoundingClientRect();
    window.scrollTo(0, rect.top - 50);
    visible.value = true;
  }
};
</script>

<style lang="scss" scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .chart-container {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 326px;
    width: 100%;
    background: transparent;

    .canvas {
      width: 100% !important;
      position: relative;
      left: 60px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 60px;
      height: 100%;
      width: 100%;
      background-color: $martech-blue;
      z-index: 1;
    }

  }

  .price-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Source Code Pro', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    right: 0;
    background-color: #E2FF30;
    font-size: 13px;
    padding: 0 16px;
    text-align: right;
    z-index: 23;
    color: $martech-black;

    .pagination-wrap {
      min-width: 143px;
    }

    .price {
      font-weight: 700;
    }
  }
</style>
