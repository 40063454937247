<template>
  <div class="range-timeline">
    <div v-for="(range, index) in data" :key="index">
      <div class="wrap">
        <img :src="timelineSvg" alt="graphic timeline" class="range-marker">
        <p>{{ range.date }}</p>
        <div
          v-for="(card, cardIndex) in range.cards"
          :key="`${card.date}-${card.title}`"
          class="content"
          :style="{top: `${card.top}px`}">
          <animated-card
            :header="{date: card.date, eventType: card.eventtype, color: card.color}"
            :body="{title: card.title, subTitle: card.subtitle, text: card.text, imageUrl: card.imageurl}"
            :class="{group: !!cardIndex}"
            :animate="card.animate"
            :jitter="card.jitter"
            :display="card.display"
            :group-size="groupSize[card.displayDate]"
            :group-position="card.groupPosition"
            @pagination="(direction) => { handleCardPagination(card.position, direction); }"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, inject } from 'vue';
import AnimatedCard from '@/components/card-elements/animate/AnimatedCard.vue';

const props = defineProps({
  timeline: {
    type: String,
    required: false,
    default: undefined,
  },
});

let startMonth;
let startYear;
let lastMonth;
let lastYear;
let numMonths = 0;

const nodes = inject('articleJson')?.[props.timeline] || [];
const compare = (a, b) => {
  const aDate = new Date(a.date);
  const bDate = new Date(b.date);

  if (aDate < bDate) return -1;
  if (bDate > aDate) return 1;
  return 0;
};
nodes.sort(compare);
for (let i = 0; i < nodes.length; i++) {
  if (nodes[i].date != null) {
    const date = new Date(nodes[i].date);
    nodes[i].date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    let displayDay = date.getDate();

    if (displayDay <= 14) {
      displayDay = 1;
    } else {
      displayDay = 15;
    }
    nodes[i].displayDate = `${date.getMonth() + 1}/${displayDay}/${date.getFullYear()}`;

    if (!startMonth) {
      startMonth = date.getMonth() + 1;
      startYear = date.getFullYear();
    }

    lastMonth = date.getMonth() + 1;
    lastYear = date.getFullYear();
  }
}

numMonths += 13 - startMonth;
numMonths += lastMonth;
numMonths += ((lastYear - 1 - startYear) * 12) + 1;

const cards = ref(nodes);

const timelineSvg = 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/lines.svg';

const groupSize = ref({});

const data = computed(() => {
  const dates = [];
  let month = startMonth;
  let year = startYear;
  for (let i = 0; i < numMonths; i++) {
    const date = new Date(`${month}/1/${year}`);
    dates.push({
      month,
      year,
      date: `${date.toLocaleString('default', { month: 'short' })} ${year}`,
      cards: [],
    });

    month++;
    if (month > 12) {
      month = 1;
      year++;
    }
  }

  let lastDate = '';
  let jitter = 0.1;

  cards.value.forEach((card, index) => {
    const [ cardMonth, cardDay, cardYear ] = card.displayDate.split('/'); // eslint-disable-line
    card.display = true;

    const key = `${cardMonth}/${cardDay}/${cardYear}`;
    if (!groupSize.value[key]) groupSize.value[key] = 0;
    groupSize.value[key]++;

    for (let i = 0; i < dates.length; i++) {
      if (cardMonth == dates[i].month && cardYear == dates[i].year) { // eslint-disable-line
        card.top = 208 * ((1 / 30) * (cardDay - 1));
        if (card.displayDate === cards.value[0].displayDate) {
          card.animate = false;
        } else {
          card.animate = true;
        }
        if (card.displayDate === lastDate) {
          card.jitter = jitter; // Maintain the same offset for cards on the same date
          card.display = false;
          card.groupPosition = dates[i].cards.length + 1;
        } else if (jitter) {
          jitter = 0; // Last had jitter so now we're back to 0
          card.jitter = 0;
        } else {
          jitter = Math.random() * 20; // Last had no jitter so this one does pushing it to the right
          card.jitter = jitter;
        }

        card.groupPosition = groupSize.value[key];
        card.position = index;

        dates[i].cards.push(card);
        lastDate = card.displayDate;
        break;
      }
    }
  });

  return dates;
});

const handleCardPagination = (cardIndex, direction) => {
  if (direction === 'next') {
    cards.value[cardIndex + 1].display = true;
  } else {
    cards.value[cardIndex - 1].display = true;
  }

  cards.value[cardIndex].display = false;
};

</script>

<style lang="scss" scoped>
.range-timeline {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .wrap {
    position: relative;
  }

  p {
    position: absolute;
    top: 30px;
    left: -45px;
    width: 100px;
    color: #E2FF30;
    transform: rotate(-90deg);
    font-size: $martech-type-14;
    font-weight: $martech-weight-semibold;
    line-height: 16px;
    font-family: 'Source Code Pro', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  .range-marker {
    height: 208px;
    width: 30px;
  }

  .content {
    overflow: visible;
    position: absolute;
    left: 30px;
    top: 0;
    min-width: 320px;
  }
}
</style>
