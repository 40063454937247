import delve from 'dlv';
import { DateHelper as dates } from '@tcgplayer/martech-components';

export default {
  created() {
    if (this.articleData) {
      this.render = (delve(this.articleData, 'deckData') || delve(this.articleData, 'deck', null)) !== null;

      // eventDate in a couple of places -> article date -> deck creation date
      this.date = dates.format(delve(this, 'articleData.deckData.eventDate')
        || delve(this, 'articleData.deck.eventDate')
        || delve(this, 'articleData.date')
        || delve(this, 'articleData.deck.created')
        || delve(this, 'articleData.deckData.created'));

      this.color = delve(this.articleData, 'deckData.colors') || delve(this.articleData, 'deck.stats.colors.color', '');
      this.companionID = delve(this.articleData, 'deckData.companionID', 0);
      this.contentType = delve(this.articleData, 'type') || delve(this.articleData, 'contentType', 'deck');
      this.deckID = delve(this.articleData, 'deckID', null);
      this.displayName = delve(this.articleData, 'deckData.deckName') || delve(this.articleData, 'deck.name', this.title);
      this.eventName = delve(this.articleData, 'deckData.eventName') || delve(this.articleData, 'deck.eventName', '');
      this.eventRank = delve(this.articleData, 'deckData.eventRank') || delve(this.articleData, 'deck.eventRank', 0);
      this.format = delve(this.articleData, 'deckData.format') || delve(this.articleData, 'deck.format', '');
      this.imageAltName = delve(this.articleData, 'imageAltName', this.title);
      this.marketPrice = delve(this.articleData, 'deckData.marketPrice') || delve(this.articleData, 'deck.stats.price.marketPrice', null);
      this.ownerName = delve(this.articleData, 'deckData.ownerName') || delve(this.articleData, 'deck.ownerName', '');
      this.vertical = delve(this.articleData, 'vertical') || delve(this.articleData, 'deck.game', null);
    }
  },
};
